import React, { useState, useEffect} from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import LandingPageForm from "../components/landingPages/LandingPageForm";
import LandingPageList from "../components/landingPages/LandingPageList";
import './LandingPageEditor.css';

const LandingPageEditor = () => {
    const [currentPage, setCurrentPage] = useState('list'); // Stan do przechowywania aktualnie wyświetlanego komponentu
    const [editData, setEditData] = useState(null); // Stan do przechowywania danych do edycji
    const location = useLocation();
    const navigate = useNavigate();
    const { page_key } = useParams();
    console.log("currentPage", currentPage);


    const token = localStorage.getItem('token');

    const handleEdit = (data) => {
      setEditData(data);
      setCurrentPage('form');
      // Zmieniamy adres URL za pomocą navigate
      navigate(`/landing-page-editor/${data}`);
  };

  useEffect(() => {
     if(!page_key) setCurrentPage('list');
     if(page_key) setCurrentPage('form');
  }, [page_key]);


    const handleCreate = () => {
        setEditData(null);
        setCurrentPage('form');
        navigate(`/landing-page-editor/create`);
    };

    return (
        <div className="container py-5">
            <h1 className="text-center">Landing Page Editor</h1>
            <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {currentPage === 'list' ? (
                        <LandingPageList onEdit={handleEdit} onCreate={handleCreate} />
                    ) : (
                        <LandingPageForm editData={editData} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default LandingPageEditor;
