import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Alert, Container } from 'react-bootstrap';
import proxy from '../../setup/proxy';

const BatchesTable = () => {
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  // Funkcja do pobierania batchy z API
  const fetchBatches = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${proxy}/api/desc/batches`);
      setBatches(response.data);
    } catch (err) {
      setError('Error fetching batches');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Funkcja do przetwarzania zakończonych batchy
  const processCompletedBatches = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);
    try {
      const response = await axios.post(`${proxy}/api/process-completed-batches`);
      setMessage('Completed batches have been processed.');
    } catch (err) {
      setError('Error processing completed batches');
      console.error(err);
    } finally {
      setLoading(false);
      fetchBatches(); // Odświeżenie listy batchy po przetworzeniu
    }
  };

  // Pobierz batchy po załadowaniu komponentu
  useEffect(() => {
    fetchBatches();
  }, []);

  return (
    <Container className="mt-4">
      <h2>Unprocessed Batches</h2>

      {/* Przycisk "Refresh" i "Process Completed Batches" */}
      <div className="mb-3">
        <Button onClick={fetchBatches} disabled={loading} className="me-2">
          {loading ? 'Refreshing...' : 'Refresh'}
        </Button>
        <Button onClick={processCompletedBatches} disabled={loading}>
          {loading ? 'Processing...' : 'Process Completed Batches'}
        </Button>
      </div>

      {/* Wiadomość o sukcesie */}
      {message && <Alert variant="success">{message}</Alert>}

      {/* Wiadomość o błędzie */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Tabela batchy */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Batch ID</th>
            <th>Status</th>
            <th>Error Message</th>
            <th>Batch Name</th>
            <th>Processed</th>
          </tr>
        </thead>
        <tbody>
          {batches.length > 0 ? (
              batches.map((batch) => (
                  <tr key={batch.batch_id}>
                  <td>{batch.batch_id || 'N/A'}</td>
                  <td>{batch.status || 'N/A'}</td>
                  <td>{batch.error_message ? batch.error_message : 'No error'}</td>
                  <td>{batch.batch_name || 'N/A'}</td>
                  <td>{batch.processed ? 'Yes' : 'No'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No unprocessed batches found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default BatchesTable;
