import "./Footer.css";

function Footer() {
  return (
    <footer className="footer d-flex align-items-center justify-content-center">
      <span>All rights reserved COMPACTOR © 2024</span>
    </footer>
  );
}

export default Footer;