import React, { useEffect, useCallback, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // Dodanie useNavigate
import axios from "axios";
import proxy from "../setup/proxy.js";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

// importing components
import ProductCard from "../components/ProductCard";
import { ProductTable } from "../components/utilities/Tables.js";
import { CategoryDivider } from "../components/visual/headers.js";
import { OfferNavigation } from "./Navigation";
import { ShowProductImagesWithResize } from '../components/ShowProductVisuals.js';
import SKUlist from "../components/SKUlist.js";
import { DownloadButton } from "../components/Buttons.js";
import ImageWithCheck from "../components/utilities/ImageWithCheck.js";
import ContactPage from './ContactPage';
import ProductModal from "../components/landingPages/ProductModal"; // Import ProductModal
import SelectedProductsDropdown from '../components/landingPages/SelectedProductsDropdown'; // Import SelectedProductsDropdown
// css
import "./LandingPage.css";




// contexts

import { useTranslationContext } from '../context/TranslationContext';
import {
  useLandingPageContext,
  LandingPageProvider,
} from "../context/LandingPageContext";

const LandingPage = () => {
  const { t } = useTranslation();
  const { lang, setLang, loadTranslations } = useTranslationContext();
  const location = useLocation();
  const navigate = useNavigate(); // Deklaracja useNavigate
  const [isContact, setIsContact] = useState(false);
  const { page_key, sku } = useParams();
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const { landingPageSkuList, setLandingPageSkuList } = useLandingPageContext();
  const isProductPage = Boolean(sku);
  const [skuList, setSkuList] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState(() => {
    const storedSkus = sessionStorage.getItem("selectedSkus");
    return storedSkus ? JSON.parse(storedSkus) : [];
  });

  // Stan dla triggera highlightu dropdown menu z listą wybranych SKU
  const [highlightTrigger, setHighlightTrigger] = useState(false);



  const triggerHighlight = () => {
    setHighlightTrigger(prev => !prev);
  };

  // Stan dla zarządzania widocznością modalu oraz przechowywania danych produktu
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Funkcja otwierająca modal z danymi produktu
  const handleProductClick = (product) => {
    const productData = findProductBySku(product.sku);
    setSelectedProduct(productData);
    setShowModal(true);
  };

  // Funkcja zamykająca modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  // Funkcja do obsługi checkboxa
  const handleCheckboxChange = (sku) => {
    setSelectedSkus((prevSelectedSkus) => {
      triggerHighlight();
      let newSelectedSkus;
      if (prevSelectedSkus.includes(sku)) {
        newSelectedSkus = prevSelectedSkus.filter((item) => item !== sku);
      } else {
        newSelectedSkus = [...prevSelectedSkus, sku];
      }
      sessionStorage.setItem("selectedSkus", JSON.stringify(newSelectedSkus));
      return newSelectedSkus;
    });
  };

  //funkcja do zaznaczania wszystkich produktów
  const handleSelectAll = () => {
    const allSkus = landingPageSkuList.map((sku) => sku.sku);
    setSelectedSkus(allSkus);
    triggerHighlight();
    sessionStorage.setItem("selectedSkus", JSON.stringify(allSkus));
  };

  //funkcja do odznaczania wszystkich produktów
  const handleDeselectAll = () => {
    setSelectedSkus([]);
    triggerHighlight();
    sessionStorage.setItem("selectedSkus", JSON.stringify([]));
  };

  // Funkcja do przechodzenia do strony kontaktowej z wybranymi SKU
  const handleAskForProducts = () => {
    navigate(`/offer/${page_key}/contact`, { state: { selectedSkus } });
  };

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (location.pathname.includes('/contact')) {
      setIsContact(true);
    } else {
      setIsContact(false);
    }
  }, [location]);

  useEffect(() => {
    try { loadTranslations(['landing_page']); }
    catch (error) {
      console.error('Error loading translations:', error);
    }
    // Załaduj tłumaczenia dla tego komponentu
  }, [loadTranslations]);

  const fetchLandingPageData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${proxy}/api/landing-page/get-cards-data?page_key=${page_key}`
      );

      // Funkcja sprawdzająca, czy obiekt jest pusty
      const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      };

      // Funkcja czyszcząca obiekt z pustych obiektów i obiektów, w których sku jest równe ""
      const cleanData = (data) => {
        if (Array.isArray(data)) {
          return data.filter(item => !isEmptyObject(item) && item.sku !== "");
        } else if (typeof data === 'object' && data !== null) {
          for (const key in data) {
            if (Array.isArray(data[key])) {
              data[key] = data[key].filter(item => !isEmptyObject(item) && item.sku !== "");
            } else if (typeof data[key] === 'object' && data[key] !== null) {
              data[key] = cleanData(data[key]);
            }
          }
          return data;
        }
        return data;
      };

      
      const cleanedData = cleanData(response.data);
      setLandingPageData(cleanedData);

      let skusMap = cleanedData.landingPageData.skus;
      skusMap = JSON.parse(skusMap);

      // Filtracja pustych obiektów oraz obiektów, w których sku jest równe ""
      const filteredSkusMap = skusMap.filter((sku) => !isEmptyObject(sku) && sku.sku !== "");

      const skuObjects = filteredSkusMap.map((sku) => ({ sku: sku.sku }));

      setSkuList(skuObjects);
      setLandingPageSkuList(skuObjects);

      // setting language
      const language = cleanedData.landingPageData.lang;
      setLang(language);

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setLandingPageData("404");
      } else {
        console.error("Error fetching landing page data:", error);
      }
    }
  }, [page_key, setLandingPageData, setLandingPageSkuList]);

  useEffect(() => {
    if (!landingPageData) {
      fetchLandingPageData();
    }
  }, [landingPageData, fetchLandingPageData]);

  if (!landingPageData) {
    return (
      <div className="error-404-container">
        <div className="error-404-text">{t('loading...')}</div>
      </div>
    );
  }

  if (landingPageData === "404") {
    return (
      <div className="error-404-container">
        <div className="error-404-text">{t('404 - no landing page found...')}</div>
      </div>
    );
  }

  // Funkcja do wyszukiwania obiektu po "sku"
  function findProductBySku(sku) {
    if (!landingPageData || !Array.isArray(landingPageData.productsTableData)) {
      return null;
    }
    // Konwersja obiektu do JSON:
    return landingPageData.productsTableData.find(product => product.sku === sku);
  }
  const productName = (product) => {
    if (!product.business_description) {
      return "";
    }
    return product.business_description.split("\n")[0];
  };

  const productSize = (product) => {
    let size = "";
    if (
      product.product_width_cm === null &&
      product.product_depth_cm === null &&
      product.product_height_cm === null
    ) {
      return "---";
    }
    if (
      product.product_width_cm !== null &&
      product.product_width_cm !== 0
    ) {
      size += `${product.product_width_cm} x `;
    }
    if (
      product.product_depth_cm !== null &&
      product.product_depth_cm !== "0"
    ) {
      size += `${product.product_depth_cm} x `;
    }
    if (
      product.product_height_cm !== null &&
      product.product_height_cm !== 0
    ) {
      size += `${product.product_height_cm}`;
    }
    size += " cm";
    return size;
  };

  const DisplayCards = () => {

    if (!landingPageData.products || landingPageData.products.length === 0) {
      return <div>{t('No products available')}</div>;
    }

    const groupedProducts = landingPageData.products.reduce((acc, product) => {
      let category = t(product.business_category);
      if (category === null || category === "") category = t('OTHER');
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(product);
      return acc;
    }, {});

    const checkDateValidity = (date) => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      if (!date) return "";
      if (date === "1969-12-31T23:00:00.000Z") return "";
      const newDate = new Date(date).toLocaleDateString("en-GB", options);

      return newDate;
    }
    

    return (
      <div className="landing-page-container">
        <div className="landing-page-header-container">
          <div className="landing-page-header-img">
            <div className="landing-page-header-text">
              <h2>Commercial Offer</h2>
              <h4>{landingPageData.landingPageData.project_name}</h4>
              <p>To: {landingPageData.landingPageData.client_name}</p>
              <p>
              {checkDateValidity(landingPageData.landingPageData.offer_date) && (
                <>
                  Offer date: {checkDateValidity(landingPageData.landingPageData.offer_date)}
                </>
              )}
              </p>
              <p>
              {checkDateValidity(landingPageData.landingPageData.exp_date) && (
                <>
                  Expiration date: {checkDateValidity(landingPageData.landingPageData.exp_date)}
                </>
              )}
              </p>
            </div>
          </div>
        </div>
        <div className="landing-page-categories-container">
        
        <div className="d-flex d-100 align-content-center justify-content-center my-5">
          {/* <span>Select the products you're interested in and send us a request</span> */}
        <button className="btn btn-primary shadow mx-2" onClick={handleSelectAll}>Select all</button>
        <button className="btn btn-secondary shadow mx-2" onClick={handleDeselectAll}>Deselect all</button>
        </div>

          {Object.keys(groupedProducts).map((category) => (
            <div key={category} className="category-section row mt-5 mx-0">
              
              <CategoryDivider category={category} />
              <div className="d-flex justify-content-center flex-wrap">
                {groupedProducts[category].map((product) => (
                  <ProductCard
                    key={product.sku}
                    imageSrc={`${proxy}/img/main/300/${product.sku}`} // Dostosuj ścieżkę do obrazów
                    productName={productName(product)}
                    productCode={product.sku}
                    productEan={product.ean}
                    productSize={productSize(product)}
                    sizeTag={product.size_tag}
                    pageKey={page_key}
                    onClick={() => handleProductClick(product)} // Dodajemy handler kliknięcia
                    handleCheckboxChange={() => handleCheckboxChange(product.sku)} // Dodajemy handler checkboxa
                    isChecked={selectedSkus.includes(product.sku)} // Sprawdzamy, czy SKU jest zaznaczone
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };


  return (
    <>
      <OfferNavigation page_key={page_key} />
      {isContact ? <ContactPage pageKey={page_key} skus={selectedSkus}/> : <DisplayCards />}
     { (showModal ? <ProductModal showModal={showModal} handleCloseModal={handleCloseModal} product={selectedProduct} landingPageData={landingPageData} skuList={skuList} handleCheckboxChange={handleCheckboxChange}  handleAskForProducts={handleAskForProducts}/> : null)}
      {!isContact && <SelectedProductsDropdown selectedSkus={selectedSkus} handleAskForProducts={handleAskForProducts}  triggerHighlight={highlightTrigger} />}
    </>
  );
};

const LandingPageWrapper = () => (
  <LandingPageProvider>
    <LandingPage />
  </LandingPageProvider>
);

export default LandingPageWrapper;
