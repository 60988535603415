import React, { useState, useEffect, useCallback, useContext } from 'react';
import proxy from '../../setup/proxy';
import { AuthContext } from '../../context/AuthContext';
import { CategoryDivider } from '../visual/headers.js';

const DescriptionsGeneral = ({ sku, data, onGeneralDataChange, activeElementRef }) => {
  const { roles } = useContext(AuthContext);
  const hasAccess = (role) => roles.includes(role);

  // Zainicjuj `localGeneralData` tylko raz
  const [localGeneralData, setLocalGeneralData] = useState(() => data);

  useEffect(() => {
    if (localGeneralData !== data) {
      setLocalGeneralData(data);
    }
  }, [data]);

  const handleInputChange = useCallback((field, value) => {
    setLocalGeneralData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    onGeneralDataChange(field, value);

  }, [onGeneralDataChange]);


  return (
    <>
      {(hasAccess('superuser') || hasAccess('descriptions_editor')) ? 
      ( <div className="container px-5">
      <CategoryDivider category="General product data" />
      <div className="row">
        <div className="col-12 col-md-3">
          <img src={`${proxy}/img/main/${sku}`} alt="Product" className="img-fluid" />
        </div>
        <div className="col-12 col-md-9">
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">SKU:</label>
            <div className="col-12 col-sm-10">
              <input type="text" className="form-control" value={sku} readOnly />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Business Description:</label>
            <div className="col-12 col-sm-10">
              <textarea
                className="form-control"
                value={localGeneralData.business_description}
                onChange={(e) => {
                  handleInputChange('business_description', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Main Category:</label>
            <div className="col-12 col-sm-10">
              <input
                type="text"
                className="form-control"
                value={localGeneralData.main_category}
                onChange={(e) => {
                  handleInputChange('main_category', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Business Category:</label>
            <div className="col-12 col-sm-10">
              <input
                type="text"
                className="form-control"
                value={localGeneralData.business_category}
                onChange={(e) => {
                  handleInputChange('business_category', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Commercial Category:</label>
            <div className="col-12 col-sm-10">
              <input
                type="text"
                className="form-control"
                value={localGeneralData.commercial_category}
                onChange={(e) => {
                  handleInputChange('commercial_category', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Room 1:</label>
            <div className="col-12 col-sm-10">
              <input
                type="text"
                className="form-control"
                value={localGeneralData.room_1}
                onChange={(e) => {
                  handleInputChange('room_1', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Room 2:</label>
            <div className="col-12 col-sm-10">
              <input
                type="text"
                className="form-control"
                value={localGeneralData.room_2}
                onChange={(e) => {
                  handleInputChange('room_2', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Room 3:</label>
            <div className="col-12 col-sm-10">
              <input
                type="text"
                className="form-control"
                value={localGeneralData.room_3}
                onChange={(e) => {
                  handleInputChange('room_3', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Function 1:</label>
            <div className="col-12 col-sm-10">
              <input
                type="text"
                className="form-control"
                value={localGeneralData.function_1}
                onChange={(e) => {
                  handleInputChange('function_1', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-12 col-sm-2 col-form-label">Additional Info (for generation):</label>
            <div className="col-12 col-sm-10">
              <textarea
                type="text"
                className="form-control"
                value={localGeneralData.additionalInfo}
                onChange={(e) => {
                  handleInputChange('additionalInfo', e.target.value);
                  activeElementRef.current = e.target; // Śledzenie aktywnego elementu
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>) : 
      (  <div className="container d-flex justify-content-center px-5">
      <div className="hstack">
        <div className="bg-light">
          <img src={`${proxy}/img/main/${sku}`} alt="Product" className="img-fluid" style={{ width: "200px" }} />
        </div>
        <div className="vr vr-blurry mx-5"></div>
        <div className="h4" style={{ width: "200px" }}>{sku}</div>
      </div>
    </div>) }

    </>
  );
};

export default React.memo(DescriptionsGeneral);
