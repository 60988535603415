import React from 'react';
import UsersTable from '../components/users/UsersTable';
import 'bootstrap/dist/css/bootstrap.min.css';

const UsersEditor = () => {
  return (
    <div>
      <UsersTable />
    </div>
  );
};

export default UsersEditor;