import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import DescriptionsGeneral from "../components/descriptions/DescriptionsGeneral";
import Descriptions from "../components/descriptions/Descriptions";
import {
  fetchGeneralData,
  saveDescriptionData,
} from "../components/descriptions/DescriptionsUtility";
import "bootstrap/dist/css/bootstrap.min.css";
import SKUlist from "../components/SKUlist";
import { getSkusListFromIntragroup } from "../components/utilities/GetMethods.js";
import "../components/descriptions/Descriptions.css"; 
import { CategoryDivider } from "../components/visual/headers.js";

// Import AuthContext 
import { AuthContext } from '../context/AuthContext';

const DescriptionEditor = () => {
  const [data, setData] = useState(null);
  const [sku, setSelectedSKU] = useState("RAN3066");
  const [skuList, setSkuList] = useState([]);
  const [languages, setLanguages] = useState({
    en: false,
    fr: true,
    es: false,
    it: false,
  });
  const [contentTypes, setContentTypes] = useState({
    website: true,
    amazon: true,
  });

  const [generalData, setGeneralData] = useState({
    business_description: "",
    main_category: "",
    business_category: "",
    commercial_category: "",
    room_1: "",
    room_2: "",
    room_3: "",
    function_1: "",
    additionalInfo: "",
  });

  const [refreshDataTrigger, setRefreshDataTrigger] = useState(false);

  const { roles, logout } = useContext(AuthContext);
  const hasAccess = (role) => roles.includes(role);

  // Ref do śledzenia aktywnego elementu
  const activeElementRef = useRef(null);

  // GET SKU LIST
  useEffect(() => {
    const fetchSkus = async () => {
      try {
        const skus = await getSkusListFromIntragroup();
        setSkuList(skus);
      } catch (error) {
        console.error("Error fetching SKU list:", error);
      }
    };

    fetchSkus();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const generalData = await fetchGeneralData(sku);

        setData(generalData);

        setGeneralData({
          business_description: generalData.business_description,
          main_category: generalData.main_category,
          business_category: generalData.business_category,
          commercial_category: generalData.commercial_category,
          room_1: generalData.room_1,
          room_2: generalData.room_2,
          room_3: generalData.room_3,
          function_1: generalData.function_1,
          additionalInfo: generalData.additionalInfo,
        });
        console.log("General data:", generalData);

        // Przywracanie fokusu po aktualizacji stanu
        if (activeElementRef.current) {
          activeElementRef.current.focus();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sku, refreshDataTrigger]);

  const handleSKUSelect = (sku) => {
    console.log("Selected SKU:", sku);
    setSelectedSKU(sku);
  };

  const handleGeneralDataChange = useCallback((field, value) => {
    setGeneralData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="row d-flex flex-columns" style={{ minHeight: "90vh" }}>
        <div className="col-2">
          <SKUlist
            onSKUSelect={handleSKUSelect}
            showVisualsQuantity={false}
            showFilterPhotosAvailable={false}
            showFilterVideosAvailable={false}
            skuList={skuList}
            showFilterIntragroupProducts={true}
          />
        </div>
        <div className="col-10">
          <div className="container mt-4 ">
            <DescriptionsGeneral
              sku={data.sku}
              data={generalData}
              onGeneralDataChange={handleGeneralDataChange}
              activeElementRef={activeElementRef} // Przekazanie ref do DescriptionsGeneral
            />
            <div className="mt-4">
              <Descriptions
                sku={sku}
                data={data}
                saveDescriptionData={saveDescriptionData}
                generalData={generalData}
                refreshGeneralData={() => {
                  setRefreshDataTrigger(!refreshDataTrigger);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionEditor;
