import React, { useState, useEffect, useMemo, useCallback, useContext} from 'react';
import { Table, InputGroup, FormControl, Pagination, DropdownButton, Dropdown, Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import axiosInstance from '../../setup/axiosInstance';
import proxy from '../../setup/proxy';
import { format } from 'date-fns';
import './LandingPageList.css';
import { use } from 'i18next';

// Import AuthContext
import { AuthContext } from '../../context/AuthContext';



const LandingPageList = ({ onEdit, onCreate }) => {
    const [data, setData] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [filter, setFilter] = useState("");
    const [creatorFilter, setCreatorFilter] = useState("All");
    const [creators, setCreators] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [pageKeyToDelete, setPageKeyToDelete] = useState(null);

    const { roles } = useContext(AuthContext);
    const hasAccess = (role) => roles.includes(role);

    const fetchData = useCallback(async () => {


        const user = localStorage.getItem("user");
        try {
            const response = await axiosInstance.post(`${proxy}/api/landing-page/get-list`, {user: user});
            setData(response.data);
            setDataToShow(response.data);


            const uniqueCreators = [...new Set(response.data.map(item => item.user))];
            setCreators(uniqueCreators);

        } catch (error) {
            console.error('Error fetching landing pages:', error);
        }
        
    }, []);

      useEffect(() => {
        fetchData();
    }, [fetchData]);




    // filtrowanie po użytkowniku
    useEffect(() => {

        if (creatorFilter === "All") setDataToShow(data);
        else{        const dataFiltered =  data.filter(item => item.user === creatorFilter);
            setDataToShow(dataFiltered);}

    }, [creatorFilter]);

    const handleDelete = async (pageKey) => {
        try {
            const response = await axiosInstance.post(`${proxy}/api/landing-page/remove`, {
                page_key: pageKey
            });
            if (response.status === 200) {
                fetchData();
            }
        } catch (error) {
            console.error('Error deleting landing page:', error);
        }
    };

    const columns = useMemo(() => [
        { 
            Header: 'Creation Date', 
            accessor: 'creation_date',
            Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'),
            className: 'col-creation-date' 
        },
        { 
            Header: 'Exp Date', 
            accessor: 'exp_date',
            Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'),
            className: 'col-exp-date' 
        },
        { Header: 'Offer Creator', accessor: 'user', className: 'col-offer-creator'},
        { Header: 'Client Name', accessor: 'client_name' },
        { Header: 'Client Representant', accessor: 'client_representant' },
        { 
            Header: 'SKUs', 
            accessor: 'skus',
            className: 'col-skus',
            Cell: ({ value }) => {
                value = JSON.parse(value);
                value = value.map((item) => item.sku)
                value = value.join(', ');
                //const displayValue = value.length > 80 ? `${value.slice(0, 80)}...` : value;
                return <span className="" title={value}>{value}</span>;
            }

        },
        { Header: 'Lang', accessor: 'lang', className: 'col-language' },
        { 
            Header: 'Page Key', 
            accessor: 'page_key',
            className: 'col-page-key',
            Cell: ({ value }) => (
                <a href={`/offer/${value}`} target="_blank" rel="noopener noreferrer">
                    {value.length > 10 ? `${value.slice(0, 10)}...` : value}
                </a>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <Button variant="primary" onClick={() => onEdit(row.original.page_key)} className="me-2">
                        Edit
                    </Button>
                    <Button variant="danger" onClick={() => {
                        setShowDeleteModal(true);
                        setPageKeyToDelete(row.original.page_key);
                    }}>
                        Delete
                    </Button>
                </div>
            )
        }
    ], [onEdit]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: { pageIndex, globalFilter }
    } = useTable({
        columns,
        data: dataToShow,
        initialState: { pageIndex: 0 }
    }, useGlobalFilter, useSortBy, usePagination);

    useEffect(() => {
        setGlobalFilter(filter);
    }, [filter, setGlobalFilter]);

    // const filteredData = useMemo(() => {
    //     if (creatorFilter === "All") return data;

    //     return data.filter(item => item.offer_creator === creatorFilter);
    // }, [data, creatorFilter]);

    return (
        <Container fluid className="my-5">
            <Row className="align-items-center justify-content-center d-flex shadow py-3 rounded">
                
                <Button variant="success" className="mb-3 button-create aligh-self-center " onClick={onCreate}>Create New Landing Page</Button>
            </Row>
            <Row className="shadow pt-5 rounded">
             
                <Col>
                
                  
                    {hasAccess("superuser") && (
                        <DropdownButton
                            id="dropdown-basic-button"
                            title={`Filter by Creator: ${creatorFilter}`}
                            onSelect={(e) => setCreatorFilter(e)}
                        >
                            <Dropdown.Item eventKey="All">All</Dropdown.Item>
                            {creators.map(creator => (
                                <Dropdown.Item key={creator} eventKey={creator}>{creator}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    )}
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                        />
                    </InputGroup>
                    
                    <div className="table-container">
                        <Table {...getTableProps()} striped bordered hover className="table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} className={cell.column.className}>{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination>
                        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        {pageOptions.map((pageNum, i) => (
                            <Pagination.Item key={i} onClick={() => gotoPage(pageNum)} active={pageNum === pageIndex}>
                                {pageNum + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                    </Pagination>
                </Col>
            </Row>

            {/* Modal for delete confirmation */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this landing page?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleDelete(pageKeyToDelete);
                        setShowDeleteModal(false);
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default LandingPageList;