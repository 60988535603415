import proxy from '../setup/proxy';
import axiosInstance from '../setup/axiosInstance';

const DeleteImages = async (filename) => {
    const filenames = [filename];
    const token = localStorage.getItem('token');
    try {
        const response = await axiosInstance.post(`${proxy}/api/img/delete-images`, {
            filename: filenames  
        }, {}); 
        return response; 

    } catch (error) {
        //console.log("responmse error ", error.response.data);
       throw error.response.data; // Zwracaj null, aby wskazać błąd w wywołaniu funkcji
    }
};

export default DeleteImages;
    
