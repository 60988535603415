import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Image, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../setup/axiosInstance';
import proxy from '../../setup/proxy';
import './EditUserModal.css';

const EditUserModal = ({ showModal, handleCloseModal, user, isEditing, onUserUpdated }) => {
  const { t } = useTranslation();

  const predefinedValues = {
    email: '',
    name: '',
    surname: '',
    password: '',
    phone1: '',
    phone2: '',
    isWhatsapp: false,
    photo: '',
    privileges: [],
    role: '',
    image: ''
  };

  const [formData, setFormData] = useState(predefinedValues);
  const [changePassword, setChangePassword] = useState(false);
  const [newPhoto, setNewPhoto] = useState(null);
  const [error, setError] = useState(null);
  const [allPossiblePrivileges, setAllPossiblePrivileges] = useState([]);

  // Pobieranie listy uprawnień
  useEffect(() => {
    const fetchPrivileges = async () => {
      try {
        const response = await axiosInstance.get(`${proxy}/api/db/privileges`);
        if (response.status === 200) {
          // Mapujemy obiekty do tablicy nazw uprawnień
          const privilegesNames = response.data.map(privilege => privilege.privilege_name);
          setAllPossiblePrivileges(privilegesNames);
        }
      } catch (error) {
        console.error('Error fetching privileges:', error);
      }
    };
  
    fetchPrivileges();
  }, []);

  // Pobieranie danych użytkownika w trybie edycji
  useEffect(() => {
    if (isEditing) {
      axiosInstance.post(`${proxy}/api/users/get-user-data`, { email: user.email })
        .then(response => {
          console.log('User data:', response.data);
          const userData = response.data[0] || {};
          userData.privileges = userData.privileges ? userData.privileges.split(', ') : [];
          setFormData(userData);
        })
        .catch(error => console.error('Error fetching user data:', error));
    } else {
      setFormData(predefinedValues);
    }
  }, [user, isEditing]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handlePrivilegesChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      privileges: checked 
        ? [...prevState.privileges, value] 
        : prevState.privileges.filter(priv => priv !== value)
    }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = document.createElement('img');
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        if (width !== height) {
          setError(t('image_must_be_square'));
          return;
        }
        if (width < 300 || width > 2000) {
          setError(t('image_size_limits'));
          return;
        }
        setError(null);
        setNewPhoto(file);
        setFormData(prevState => ({
          ...prevState,
          photo: URL.createObjectURL(file)
        }));
      };
    }
  };

  const handleSave = () => {
    const updatedUserData = {
      ...formData,
      privileges: formData.privileges.join(', ')
    };
  
    const formDataToSend = new FormData();
    Object.entries(updatedUserData).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        formDataToSend.append(key, value);
      } else {
        formDataToSend.append(key, '');
      }
    });
    if (newPhoto) {
      formDataToSend.append('photo', newPhoto);
    }
  
    // Dodaj logi, aby sprawdzić zawartość formDataToSend
    for (let pair of formDataToSend.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  
    const endpoint = isEditing ? `${proxy}/api/users/update-user` : `${proxy}/api/users/register`;
  
    axiosInstance.post(endpoint, formDataToSend, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(() => {
      handleCloseModal();
      if (onUserUpdated) {
        onUserUpdated();
      }
    })
    .catch(error => console.error('Error updating user data:', error));
  };
  
  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? t('edit_user') : t('add_user')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail" className="pt-4">
              <Form.Label>{t('email')}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email || ''}
                onChange={handleInputChange}
                disabled={isEditing}
              />
            </Form.Group>
            <Form.Group controlId="formName" className="pt-4"> 
              <Form.Label>{t('name')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name || ''}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formSurname" className="pt-4">
              <Form.Label>{t('surname')}</Form.Label>
              <Form.Control
                type="text"
                name="surname"
                value={formData.surname || ''}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formRole" className="pt-4">
              <Form.Label>{t('role')}</Form.Label>
              <Form.Control
                type="text"
                name="role"
                value={formData.role || ''}
                onChange={handleInputChange}
              />
            </Form.Group>
            {isEditing ? (
              <Form.Group controlId="formPassword" className="pt-4">
                <Form.Label>{t('password')}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password || ''}
                    onChange={handleInputChange}
                    disabled={!changePassword}
                  />
                  <Button onClick={() => setChangePassword(!changePassword)}>
                    {changePassword ? t('cancel_password_change') : t('change_password')}
                  </Button>
                </InputGroup>
              </Form.Group>
            ) : (
              <Form.Group controlId="formPassword" className="pt-4">
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            )}
            <Form.Group controlId="formPhone1" className="pt-4">
              <Form.Label>{t('phone')} 1</Form.Label>
              <Form.Control
                type="text"
                name="phone1"
                value={formData.phone1 || ''}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhone2" className="pt-4">
              <Form.Label>{t('phone')} 2</Form.Label>
              <Form.Control
                type="text"
                name="phone2"
                value={formData.phone2 || ''}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formHasWhatsapp" className="pt-4">
              <Form.Check
                type="checkbox"
                name="isWhatsapp"
                label={t('has_whatsapp')}
                checked={formData.isWhatsapp || false}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhoto" className="pt-4">
              <Form.Label>{t('photo')}</Form.Label>
              <div className="mb-3">
                <Image
                  className="img-user rounded-circle"
                  src={
                    newPhoto
                      ? URL.createObjectURL(newPhoto)
                      : formData.image
                      ? `${proxy}${formData.image}`
                      : `${proxy}/content/users/default.jpg`
                  }
                  rounded
                />
              </div>
              <Form.Control 
                type="file"
                onChange={handlePhotoChange}
              />
              {error && <div className="text-danger">{error}</div>}
            </Form.Group>
            <Form.Group controlId="formPrivileges" className="pt-4">
              <Form.Label>{t('privileges')}</Form.Label>
              {allPossiblePrivileges.length > 0 ? (
                allPossiblePrivileges.map((privilege, index) => (
                  <Form.Check
                    key={index}
                    type="switch"
                    label={t(privilege)}
                    value={privilege}
                    checked={formData.privileges.includes(privilege)}
                    onChange={handlePrivilegesChange}
                  />
                ))
              ) : (
                <div>{t('loading_privileges')}</div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {t('save_changes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUserModal;
