import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './VisualBrowser.css';
import {CloseButton} from './Buttons.js';

const VisualBrowser = ({ isVisible, images, currentIndex, onClose }) => {
    if (!isVisible) return null;

    const items = images.map(item => ({
        original: item.src,
        thumbnail: item.thumbnail,
        renderItem: () => (
            item.file_type === 'photo' ? (
                <img src={item.src} alt={item.filename} className="visual-browser-image" />
            ) : (
                <video controls className="visual-browser-video">
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )
        )
    }));

    return (
        <div className="visual-browser-overlay" onClick={onClose}>
            <button className="close-button" onClick={onClose}>
                    <CloseButton className="close-button-svg" />
                </button>
            <div className="visual-browser-content" onClick={(e) => e.stopPropagation()}>
                
                <ImageGallery items={items} startIndex={currentIndex} showThumbnails={true} showFullscreenButton={false}  showPlayButton={false}/>
            </div>
        </div>
    );
};

export default VisualBrowser;
