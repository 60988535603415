
import React from 'react';

const ProductsColumnSelector = ({ availableColumns, selectedColumns, onColumnsChange }) => {
    const handleColumnChange = (column) => {
        const newColumns = selectedColumns.includes(column)
            ? selectedColumns.filter(col => col !== column)
            : [...selectedColumns, column];

        onColumnsChange(newColumns);
        console.log('selected columns:', newColumns);
    };

    return (
        <div>
            {availableColumns.map(column => (
                <label key={column}>
                    <input
                        type="checkbox"
                        checked={selectedColumns.includes(column)}
                        onChange={() => handleColumnChange(column)}
                    />
                    {column}
                </label>
            ))}
        </div>
    );
};

export default ProductsColumnSelector;