import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import axiosInstance from "../setup/axiosInstance";
import proxy from "../setup/proxy.js";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { set } from "date-fns";

const SkuEanConverter = () => {
  const [codes, setCodes] = useState("");
  const [result, setResult] = useState("");
  const [converterSwitch, setConverterSwitch] = useState(true);
  const [apiPath, setApiPath] = useState(`${proxy}/api/products/sku-ean-converter`);

  const handleSwitch = () => {
    setConverterSwitch(!converterSwitch);
    setApiPath(
      converterSwitch
        ? `${proxy}/api/products/ean-sku-converter`
        : `${proxy}/api/products/sku-ean-converter`
    );
  };

  const handleInputChange = (e) => {
    setCodes(e.target.value);
  };

  const formatCodes = (codes) => {
    return codes
      .split(/[,\s;]+/)
      .filter((code) => code.trim() !== "")
      .map((code) => code.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedCodes = formatCodes(codes);
    setCodes(formattedCodes.join("\n"));
    
    try {
      const response = await axiosInstance.post(apiPath, {
        codes: formattedCodes,
        type: converterSwitch ? "sku" : "ean",
      });
      setResult(response.data.map(item => converterSwitch ? item.ean : item.sku).join("\n"));
    } catch (error) {
      console.error("Error converting codes:", error);
      setResult("Error occurred while converting codes");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <Card className="w-75">
        <Card.Body>
          <Card.Title className="text-center mb-4">SKU - EAN Converter</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                id="converterSwitch"
                label={`Conversion Type: ${converterSwitch ? "SKU to EAN" : "EAN to SKU"}`}
                checked={converterSwitch}
                onChange={handleSwitch}
              />
            </Form.Group>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="codesInput">Enter Codes:</Form.Label>
                  <Form.Control
                    as="textarea"
                    id="codesInput"
                    value={codes}
                    onChange={handleInputChange}
                    rows="10"
                    placeholder="Enter SKU or EAN codes, separated by commas, spaces or semicolons."
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="resultOutput">Converted Result:</Form.Label>
                  <Form.Control
                    as="textarea"
                    id="resultOutput"
                    value={result}
                    readOnly
                    rows="10"
                    placeholder="Converted codes will appear here..."
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Convert
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export { SkuEanConverter };
