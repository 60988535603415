import React, { useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductsBrowserTable.css';

const ProductsBrowserTable = ({ products, selectedColumns }) => {
    const [expandedRows, setExpandedRows] = useState({});

    const toggleExpandRow = (rowIndex, cellIndex) => {
        setExpandedRows(prev => ({
            ...prev,
            [`${rowIndex}-${cellIndex}`]: !prev[`${rowIndex}-${cellIndex}`],
        }));
    };

    const columns = useMemo(() => {
        if (!products.length) return [];

        const allColumns = Object.keys(products[0]).map(key => ({
            Header: key.charAt(0).toUpperCase() + key.slice(1),
            accessor: key,
        }));

        return Array.isArray(selectedColumns)
            ? allColumns.filter(col => selectedColumns.includes(col.accessor))
            : allColumns;
    }, [selectedColumns, products]);

    const data = useMemo(() => products, [products]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useSortBy,
        usePagination
    );

    return (
        <div className="table-responsive">
            <table {...getTableProps()} className="table table-striped table-hover table-bordered table-custom">
                <thead className="thead-dark">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th 
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}  // Zwijanie tekstu w nagłówkach
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, cellIndex) => (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        <div>
                                            {expandedRows[`${rowIndex}-${cellIndex}`] ? (
                                                <div>
                                                    {cell.render('Cell')}
                                                    <button 
                                                        className="btn btn-link p-0" 
                                                        onClick={() => toggleExpandRow(rowIndex, cellIndex)}
                                                    >
                                                        Zwiń
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    {cell.render('Cell').toString().length > 50 ? `${cell.render('Cell').toString().slice(0, 50)}...` : cell.render('Cell')}
                                                    {cell.render('Cell').toString().length > 50 && (
                                                        <button 
                                                            className="btn btn-link p-0" 
                                                            onClick={() => toggleExpandRow(rowIndex, cellIndex)}
                                                        >
                                                            Rozwiń
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="pagination d-flex justify-content-between align-items-center">
                <div>
                    <button className="btn btn-primary me-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>
                    <button className="btn btn-primary" onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>
                </div>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <select
                    className="form-select w-auto"
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default ProductsBrowserTable;
