import React, { createContext, useState, useContext } from 'react';

const LandingPageContext = createContext();

export const useLandingPageContext = () => useContext(LandingPageContext);

export const LandingPageProvider = ({ children }) => {
  const [landingPageData, setLandingPageData] = useState(null);
  const [landingPageSkuList, setLandingPageSkuList] = useState([]);
  
  return (
    <LandingPageContext.Provider value={{ landingPageData,landingPageSkuList,setLandingPageSkuList, setLandingPageData }}>
      {children}
    </LandingPageContext.Provider>
  );
};
