import CsvUploader from "../components/forms/CsvUploader";

const UpdateProductsData = () => {
    return (
        <div>
        <CsvUploader endpoint="/api/products/update" showHeadersRow={true} />
        </div>
    );
    }

export default UpdateProductsData;