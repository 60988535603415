import React, { useState, useEffect, createContext, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";
import Home from "./pages/Home";
import ImageOrder from "./pages/ImageOrder";
import ShowImagesResize from "./pages/BrowseVisuals";
import Login from "./components/Login";
import { Navigation } from "./pages/Navigation";

import { PopupProvider } from "./context/PopupContext"; 
import { GetProductsData } from "./pages/GetProductsData";
import LandingPage from "./pages/LandingPage";
import Footer from "./components/Footer";
//description pages
import DescriptionsEditor from "./pages/DescriptionsEditor";
import DescriptionGetForm from "./pages/DescriptionsGetForm";
import DescriptionsBulkGeneration from "./pages/DescriptionsBulkGeneration";

import LandingPageEditor from "./pages/LandingPageEditor";
import ScrollToTop from "./components/utilities/ScrollToTop";
import UsersEditor from "./pages/UsersEditor";
import Changelog from "./pages/Changelog";
import ProductsBrowser from "./pages/ProductsBrowser";
import AdminControl from "./pages/AdminControl";
import {SkuEanConverter} from "./pages/SkuEanConverter";

import UpdateProductsData from "./pages/UpdateProductsData";

import Maintenance from "./pages/Maintenance";

import axiosInstance from "./setup/axiosInstance";
import proxy from "./setup/proxy";





// import styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// Importowanie plików do tłumaczeń
import { TranslationProvider } from './context/TranslationContext';
import i18n from './i18n';

// Importowanie kontekstu autoryzacji
import { AuthProvider, AuthContext } from './context/AuthContext';
import { set } from "date-fns";

const MainLayout = ({ children, isLoggedIn }) => {
  const location = useLocation();
  const isOfferPage = location.pathname.startsWith('/offer');

  return (
    <div className="main-container">
      {isOfferPage ? "" : isLoggedIn ? <Navigation /> : ""}
      {children}
    </div>
  );
};

// Komponent do sprawdzania trybu konserwacji
const CheckMaintenance = ({ setIsUnderMaintenance }) => {
  const navigate = useNavigate();
  const { roles, logout } = useContext(AuthContext);
  const hasAccess = (role) => roles.includes(role);
  const { isLoggedIn, isLoading } = useContext(AuthContext);

  useEffect(() => {

    const checkMaintenanceState = async () => {
      try {
        const response = await axiosInstance.get(`${proxy}/api/app-state`);
        let { isUnderMaintenance } = response.data;
        if(isUnderMaintenance === "0") isUnderMaintenance = false;
        if(isUnderMaintenance === "1") isUnderMaintenance = true;

        setIsUnderMaintenance(isUnderMaintenance);


        if (isUnderMaintenance && !hasAccess("superuser") && isLoggedIn) {
          navigate('/maintenance'); // Przekierowanie na stronę "maintenance"
        } else {
          setIsUnderMaintenance(false); // Jeśli nie ma maintenance, aplikacja działa normalnie
        }
      } catch (error) {
        console.error("Error fetching app state:", error);
      } finally {
      }
    };
    checkMaintenanceState();
  }, [navigate, setIsUnderMaintenance]);

  return null;
};

const AppContent = () => {
  const { isLoggedIn, isLoading } = useContext(AuthContext);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(true);

  if (isLoading) {
    return <div>Loading...</div>;  // Wyświetlamy loader do momentu załadowania
  }

  return (
    <Router>
      <BackNavigationHandler />
      <PopupProvider>
        <ScrollToTop />
        {/* Przekazujemy zawartość jako children do MainLayout */}
        <MainLayout isLoggedIn={isLoggedIn}>
          <CheckMaintenance setIsUnderMaintenance={setIsUnderMaintenance} />
          <Routes>
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/offer/:page_key/:sku" element={<LandingPage />} />
            <Route path="/offer/:page_key" element={<LandingPage />} />
            <Route path="/offer" element={<LandingPage />} />
            <Route path="/offer/:page_key/contact" element={<LandingPage />} />
            <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
            <Route path="/image-order" element={isLoggedIn ? <ImageOrder /> : <Navigate to="/login" />} />
            <Route path="/image-show" element={isLoggedIn ? <ShowImagesResize /> : <Navigate to="/login" />} />
            <Route path="/get-products-data" element={isLoggedIn ? <GetProductsData /> : <Navigate to="/login" />} />
            <Route path="/update-products-data" element={isLoggedIn ? <UpdateProductsData /> : <Navigate to="/login" />} />
            <Route path="/products-browser" element={isLoggedIn ? <ProductsBrowser /> : <Navigate to="/login" />} />
            <Route path="/sku-ean-converter" element={isLoggedIn ? <SkuEanConverter /> : <Navigate to="/login" />} />
            <Route path="/descriptions-editor" element={isLoggedIn ? <DescriptionsEditor /> : <Navigate to="/login" />} />
            <Route path="/descriptions-export-excel" element={isLoggedIn ? <DescriptionGetForm /> : <Navigate to="/login" />} />
            <Route path="/descriptions-bulk-generation" element={isLoggedIn ? <DescriptionsBulkGeneration /> : <Navigate to="/login" />} />
            <Route path="/landing-page-editor/:page_key?" element={isLoggedIn ? <LandingPageEditor /> : <Navigate to="/login" />} />
            <Route path="/users-editor" element={isLoggedIn ? <UsersEditor /> : <Navigate to="/login" />} />
            <Route path="/admin-control" element={isLoggedIn ? <AdminControl /> : <Navigate to="/login" />} />
            <Route path="/login" element={!isLoggedIn ? <Login /> : <Navigate to="/" replace />} />
            <Route path="/changelog" element={isLoggedIn ? <Changelog /> : <Navigate to="/login" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </MainLayout>
      </PopupProvider>
      <Footer />
    </Router>
  );
};




const BackNavigationHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      navigate(-1);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
      
      }
    };

    window.addEventListener('popstate', handlePopState);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  return null;
};



  const App = () => (
 
    <TranslationProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </TranslationProvider>
  
  );

export default App;
