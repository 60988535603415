import proxy from '../../setup/proxy.js';
import axiosInstance from '../../setup/axiosInstance.js';



const getAllSkusList = async () => {

        try {
        const response = await axiosInstance.get(`${proxy}/api/products/get-sku-list`);
        return response.data;
    //   setSelectedSKU(selectedSku);
        } catch (error) {
            throw new Error(`Error fetching SKU list: ${error}`);
        }
}

const getSkusListFromIntragroup = async () => {
    try {
        const response = await axiosInstance.get(`${proxy}/api/products/get-sku-list-intra`);
        return response.data;
    } catch (error) {
        throw new Error(`Error fetching SKU list: ${error}`);
    }
}

  const GetLandingPageSkus = async (pageKey) => {
    try {
      const response = await axiosInstance.post(`${proxy}/api/landing-page/get-sku-list`, { page_key: pageKey });
      const skuArray = response.data;
      const skuObjects = skuArray.map(sku => ({ sku }));
      return(skuObjects);
    } catch (error) {
      console.log(error);
    }
  }

  const DownloadImages = async (skus, size, showPopupSmall) => {
    try {
      showPopupSmall('Preparing files to download. Please wait', 'success');

      const response = await axiosInstance.post(`${proxy}/img/download/`, { skus, size }, {
        responseType: 'blob' // Odbierz odpowiedź jako blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'images.zip'); // Ustaw nazwę pliku
      document.body.appendChild(link);
      link.click();
      link.remove();

      showPopupSmall('success', 'download_visual_content', 'success');
    } catch (error) {
      console.error('Error downloading the file:', error);
      showPopupSmall('error', 'download_visual_content', 'error');
    }
  }

const GetExcelOffer = async (skus, showPopupSmall, lang=null) => {
  try {
    const response = await axiosInstance.post(`${proxy}/api/products/create-offer-file`, { skus, lang }, {
      responseType: 'blob' // Odbierz odpowiedź jako blob
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'CompactorOffer.xlsx'); // Ustaw nazwę pliku
    document.body.appendChild(link);
    link.click();
    link.remove();

    showPopupSmall('success', 'download_visual_content', 'success');
  } catch (error) {
    console.error('Error downloading the file:', error);
    showPopupSmall('error', 'download_visual_content', 'error');
  }
}

export { getAllSkusList,getSkusListFromIntragroup, GetLandingPageSkus, DownloadImages, GetExcelOffer };
