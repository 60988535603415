import React from "react";
import DescriptionsSKUform from "../components/descriptions/DescriptionsSKUform";
import DescriptionsUploadCSVForm from "../components/descriptions/DescriptionsUploadCSVForm";
import CsvUploader from "../components/forms/CsvUploader";
import BatchesTable from "../components/descriptions/BatchesTable";

const DescriptionsBulkGeneration = () => {
    return (
        <div>
            <DescriptionsSKUform />
            <CsvUploader endpoint="/api/desc/generate-jsonl" />
            <BatchesTable />
        </div>
    );
};

export default DescriptionsBulkGeneration;