import React, { useState } from 'react';

const SearchSKUForm = ({ onSearch }) => {
    const [skuInput, setSkuInput] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        //const skuList = skuInput.split(/[\s,]+/).filter(sku => sku); // Rozdzielanie na SKU
        onSearch(skuInput);
    };

    return (
        <form onSubmit={handleSubmit}>
            <textarea 
                value={skuInput} 
                onChange={(e) => setSkuInput(e.target.value)} 
                placeholder="Wpisz SKU produktów oddzielone spacjami, przecinkami lub nową linią"
                rows="4"
                style={{ width: '100%' }}
            />
            <button type="submit">Szukaj</button>
        </form>
    );
};

export default SearchSKUForm;
