import React, { useContext } from "react";
import { Link, NavLink } from 'react-router-dom';
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import "./Navigation.css";
import { useTranslation } from 'react-i18next';
import { usePopup } from '../context/PopupContext.js';
import proxy from '../setup/proxy.js';
import axios from 'axios';
import ChangeLanguageMenu from '../components/utilities/ChangeLanguage.js';

// import bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

import { DownloadImages, GetExcelOffer } from "../components/utilities/GetMethods";

// iomport landing page context
import { useLandingPageContext } from "../context/LandingPageContext";

// Import AuthContext 
import { AuthContext } from '../context/AuthContext';




function Navigation() {
  const { t } = useTranslation();
  const { roles, logout } = useContext(AuthContext);
  const hasAccess = (role) => roles.includes(role);

  return (
    <Navbar bg="light" expand="lg" className="sticky-top nav-bar-top shadow">
      <Container fluid>
        <Navbar.Brand>
        <img
            src={`${proxy}/content/logos/compactor-logo.png`}
            className="nav-bar-top-logo"
            alt="Compactor logo"
          />

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
       
        <Nav className="mx-auto nav-separator">
      <Nav.Item>
        <Nav.Link as={NavLink} to="/" className="active-link">
          {t('home')}
        </Nav.Link>
      </Nav.Item>
{/* Menu for visuals */}
        <Dropdown as={Nav.Item}>
            <Dropdown.Toggle
              as={Nav.Link}
              className="active-link"
              id="visuals-dropdown"
            >
              {t('visuals')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
      {(hasAccess("superuser") || hasAccess("image_order")) && (
          <Dropdown.Item as={NavLink} to="/image-order" className="active-link">
            {t('image_order')}
          </Dropdown.Item>
      )}
      {(hasAccess("superuser") || hasAccess("visual_browser")) && (
        <Dropdown.Item as={NavLink} to="/image-show" className="active-link">
            {t('visuals_browser')}
        </Dropdown.Item>
      )}
          </Dropdown.Menu>
          </Dropdown>
{/* Menu for products */}
      {(hasAccess("superuser") || hasAccess("internal_quotation") || hasAccess("customer_quotation")) && (
          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle
              as={Nav.Link}
              className="active-link"
              id="products-dropdown"
            >
              {t('products')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item  as={NavLink} to="/get-products-data" className="active-link">
                    {t('get_products_data')}
                </Dropdown.Item>
                {(hasAccess("superuser")) && (
                   <Dropdown.Item  as={NavLink} to="/update-products-data" className="active-link">
                   {t('update_products_data')}
               </Dropdown.Item>
                )}
                <Dropdown.Item  as={NavLink} to="/products-browser" className="active-link">
                    {t('superuser')}
                </Dropdown.Item>
                <Dropdown.Item  as={NavLink} to="/sku-ean-converter" className="active-link">
                    {t('sku_ean_converter')}
                </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
      )}
{/* Menu for Descriptions */}
       {(hasAccess('superuser') || hasAccess('description_viewer')) && (
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className="active-link"
                  id="descriptions-dropdown"
                >
                  {t('descriptions')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={NavLink} to="/descriptions-editor">
                   {hasAccess('description_viewer') ? t('descriptions_editor') : t('descriptions_viewer')}
                  </Dropdown.Item>
                  <Dropdown.Item as={NavLink} to="/descriptions-export-excel">
                    {t('export_excel')}
                  </Dropdown.Item>
                  {hasAccess('superuser') ?
                 ( <Dropdown.Item as={NavLink} to="/descriptions-bulk-generation">
                    {t('bulk_generation')}
                  </Dropdown.Item>) : null}
                </Dropdown.Menu>
              </Dropdown>
            )}
      {(hasAccess("superuser") || hasAccess("landing_page_editor")) && (
        <Nav.Item>
          <Nav.Link as={NavLink} to="/landing-page-editor" className="active-link">
            {t('landing_pages_editor')}
          </Nav.Link>
        </Nav.Item>
      )}
      {(hasAccess("superuser") || hasAccess("users_editor")) && (
        <Nav.Item>
          <Nav.Link as={NavLink} to="/users-editor" className="active-link">
            {t('users_editor')}
          </Nav.Link>
        </Nav.Item>
      )}
      {(hasAccess("superuser")) && (
        <Nav.Item>
          <Nav.Link as={NavLink} to="/admin-control" className="active-link">
            {t('admin_control')}
          </Nav.Link>
        </Nav.Item>
      )}
      
      <div className="ps-5 px-0 m-0 align-content-center">
      <button className="btn btn-secondary btn-sm" onClick={logout}>
              {t('logout')}
            </button>

            </div>
    </Nav>



        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
};



const OfferNavigation = ({ page_key, sku }) => {
  
  //get landingPageSkuList from context
  const { landingPageSkuList, landingPageData } = useLandingPageContext();

  const lang = landingPageData.landingPageData.lang;

  const { t } = useTranslation();
  const { showPopupSmall } = usePopup();
  
  const handleDownloadVisuals = (event) => {
    
    console.log("landingPageSkuList", landingPageSkuList);
    if (landingPageSkuList.length > 0) {
      const skus = landingPageSkuList.map(item => item.sku); // Wyciągnięcie SKU z obiektów
      DownloadImages(skus, 0, showPopupSmall);
    } else {
      console.log('No SKUs available');
    }
  };

  const handleDownloadPresentation = async (event) => {
    const storedSkus = sessionStorage.getItem("selectedSkus");

    showPopupSmall("A presentation is in preparation...", "success");

  if (!storedSkus || storedSkus.length === 0 || storedSkus === "[]") {
    showPopupSmall("No products selected", "error");
    console.log("No products selected");
    return null;
  }

    if (storedSkus.length > 0) {
      const skus = landingPageSkuList.map(item => item.sku); // Wyciągnięcie SKU z obiektów
      // GetExcelOffer(skus, showPopupSmall); // dodaj lang w przyszłości

      try {
        const response = await axios.post(`${proxy}/api/landing-page/generate-offer-pptx`, {
          skus: storedSkus,
          lang: lang
        }, {
          responseType: 'arraybuffer'
        });
  
      
       // Odebranie pliku pptx
       const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' }));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'CompactorOffer.pptx'); // Ustaw nazwę pliku
       document.body.appendChild(link);
       link.click();
       link.remove();
     } catch (error) {
       showPopupSmall("Error occured. Please contact us to report the problem", "error");
     }
   } else {
     console.log('No SKUs available');
     showPopupSmall("No SKUs available", "error");
   }
 }
 return (
  <Navbar bg="light" expand="lg" className="sticky-top nav-bar-top shadow">
    <Container fluid>
      <div className="navbar-brand-container">
        <Navbar.Brand as={Link} to={`/offer/${page_key}`}>
          <img
            src={`${proxy}/content/logos/compactor-logo.png`}
            className="nav-bar-top-logo"
            alt="Compactor logo"
          />
        </Navbar.Brand>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link as={Link} to={`/offer/${page_key}`}>{t('catalog')}</Nav.Link>
          <Nav.Link onClick={handleDownloadPresentation}>{t('export_pptx')}</Nav.Link>
          {/* <Nav.Link onClick={handleDownloadVisuals}>{t('download_all_visuals')}</Nav.Link> */}
          <Nav.Link as={Link} to={`/offer/${page_key}/contact`}>{t('contact')}</Nav.Link>
        </Nav>
        <div className="store-link-container">
          <Nav>
            <Nav.Link href="https://compactorstore.com" target="_BLANK" className="store-link">{t('visit_store')}</Nav.Link>
          </Nav>
        </div>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
  };
  

export { Navigation, OfferNavigation };
