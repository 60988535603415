import React, { useState, useMemo, useEffect } from 'react';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { Table, Container, Row, Col, Button, Badge, InputGroup, FormControl } from 'react-bootstrap';
import axiosInstance from '../../setup/axiosInstance.js';
import EditUserModal from './EditUserModal.js';
import { useTranslation } from 'react-i18next';
import './UsersTable.css';

const UsersTable = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleAddUser = () => {
    setSelectedUser(null);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedUser(null);
  };

  const handleUserUpdated = () => {
    fetchUsers();
  };

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/api/users/get-users-list');
      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDelete = (email) => {
    const newUsers = users.filter(user => user.email !== email);
    setUsers(newUsers);
  };

  const handleChangePassword = (email) => {
    alert(`Change password for ${email}`);
  };

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: row => `${row.name} ${row.surname}`,
      id: 'fullName',
      className: 'column-name',
      Cell: ({ value }) => <span>{value}</span>
    },
    { Header: 'Email', accessor: 'email', id: 'email' },
    {
      Header: 'Role', accessor: 'role', id: 'role',
      className: 'column-role'
    },
    {
      Header: 'Access',
      id: 'access',
      accessor: 'privileges',
      Cell: ({ value }) => {
        const translatedPrivileges = (value ? value.split(', ') : []).map(priv => t(priv));
        return (
          <div>
            {translatedPrivileges.map((priv, index) => (
              <Badge
                key={index}
                className={priv === t('superuser') ? 'me-1 bg-success' : 'me-1 bg-primary'}
              >
                {priv}
              </Badge>
            ))}
          </div>
        );
      }
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      id: 'actions',
      className: 'column-actions',
      Cell: ({ row }) => (
        <div>
          <Button
            variant="warning"
            onClick={() => handleEdit(row.original)}
            className="me-2"
          >
            {t('edit')}
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDelete(row.original.email)}
          >
            {t('delete')}
          </Button>
        </div>
      )
    }
  ], [users]);

  const data = useMemo(() => users, [users]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter }
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  useEffect(() => {
    setGlobalFilter(filter);
  }, [filter, setGlobalFilter]);

  return (
    <Container className="my-5">
      <Row className="mb-4 align-items-center">
        <Col>
          <h1>{t('manage_users')}</h1>
        </Col>
        <Col xs="auto">
          <Button variant="primary" onClick={handleAddUser}>
            {t('add_user')}
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <InputGroup className="mb-3">
            <FormControl
              placeholder={t('search')}
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table {...getTableProps()} striped bordered hover className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={column.style}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} style={cell.column.style}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      {showEditModal && (
        <EditUserModal
          showModal={showEditModal}
          handleCloseModal={handleCloseEditModal}
          user={selectedUser}
          isEditing={Boolean(selectedUser)}
          onUserUpdated={handleUserUpdated}
        />
      )}
    </Container>
  );
};

export default UsersTable;
