import React, { useState, useEffect } from 'react';

const ImageWithCheck = ({ src, alt, className }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsValid(true);
    img.onerror = () => setIsValid(false);
  }, [src]);

  if (!isValid) {
    return null;
  }

  return <img className={className} src={src} alt={alt} />;
};

export default ImageWithCheck;
