import React from 'react';
import changelogData from '../changelog.json';
import { Container, Card, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const renderChanges = (changes) => {
  return changes.map((change, index) => {
    if (typeof change === 'string') {
      return <ListGroup.Item key={index}>{change}</ListGroup.Item>;
    } else {
      return (
        <ListGroup.Item key={index}>
          <strong>{change.title}</strong>
          <ListGroup variant="flush">
            {change.subchanges.map((subchange, subIndex) => (
              <ListGroup.Item key={subIndex}>- {subchange}</ListGroup.Item>
            ))}
          </ListGroup>
        </ListGroup.Item>
      );
    }
  });
};

const ChangelogComponent = () => {
  return (
    <Container className="m-5 p-5">
      <div className="p-5">
      <h1 className="mb-4">Changelog</h1>
      {changelogData.map((entry, index) => (
        <Card key={index} className="mb-3">
          <Card.Header>
            <h2 className="h5 mb-0">{entry.version} - {entry.date}</h2>
          </Card.Header>
          <Card.Body>
            <ListGroup variant="flush">{renderChanges(entry.changes)}</ListGroup>
          </Card.Body>
        </Card>
      ))}
      </div>
    </Container>
  );
};

export default ChangelogComponent;
