import './headers.css';

const CategoryDivider = ({ category, additional_text }) => {
  return (
    <div className="headers-category-container">
      <div className="headers-category-text-container">
        <span className="headers-category-text">{category}</span>
        {additional_text && <span className="pe-2">{additional_text}</span>}
      </div>
      <div className="headers-category-line"></div>
    </div>
  );
};



export  {CategoryDivider};