import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import proxy from "../setup/proxy.js";
import axios from "axios";
import QRCode from "qrcode.react";
import "./ContactPage.css";
import { use } from "i18next";


const CustomSVG = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 122.87"
    style={{ enableBackground: "new 0 0 122.88 122.87" }}
    xmlSpace="preserve"
    className="svg-icon-confirmation"
  >
    <style type="text/css">
      {`
        .st0{fill:#39B54A;}
        .st1{fill:#3C3C3C;}
      `}
    </style>
    <g>
      <path
        className="st0"
        d="M32.82,51.34l14.99-0.2l1.12,0.29c3.03,1.74,5.88,3.74,8.54,5.99c1.92,1.63,3.76,3.4,5.5,5.32 c5.38-8.65,11.11-16.6,17.16-23.9c6.63-8,13.66-15.27,21.05-21.9l1.46-0.56h16.36l-3.3,3.66c-10.13,11.26-19.33,22.9-27.64,34.9 C79.74,66.97,72.31,79.37,65.7,92.13l-2.06,3.97l-1.89-4.04c-3.49-7.48-7.66-14.35-12.64-20.49c-4.98-6.14-10.77-11.59-17.52-16.22 L32.82,51.34L32.82,51.34L32.82,51.34z"
      />
      <path
        className="st1"
        d="M61.44,0c9.53,0,18.55,2.17,26.61,6.04c-3.3,2.61-6.36,5.11-9.21,7.53c-5.43-1.97-11.28-3.05-17.39-3.05 c-14.06,0-26.79,5.7-36,14.92c-9.21,9.22-14.92,21.94-14.92,36c0,14.06,5.7,26.78,14.92,36s21.94,14.92,36,14.92 c14.06,0,26.79-5.7,36-14.92c9.22-9.22,14.91-21.94,14.91-36c0-3.34-0.32-6.62-0.94-9.78c2.64-3.44,5.35-6.88,8.11-10.28 c2.17,6.28,3.35,13.04,3.35,20.06c0,16.96-6.88,32.33-17.99,43.44c-11.12,11.12-26.48,18-43.44,18c-16.96,0-32.32-6.88-43.44-18 C6.88,93.76,0,78.4,0,61.44C0,44.47,6.88,29.11,17.99,18C29.11,6.88,44.47,0,61.44,0L61.44,0L61.44,0z"
      />
    </g>
  </svg>
);

const ContactForm = React.memo(
  ({ handleSubmit, handleInputChange, formData, skus }) => {
    return (
      <Card.Body>
        <Card.Title>Contact Form</Card.Title>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="fullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter your contact number"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleInputChange}
              required
              className="form-message"
            />
          </Form.Group>
          {skus.length > 0 && (
            <Form.Group className="mb-3" controlId="formSkus">
              <Form.Label>Selected SKUs</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={skus.join(", ")}
                readOnly
              />
            </Form.Group>
          )}
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Card.Body>
    );
  }
);

const ContactPage = ({ pageKey, skus }) => {
  const [userInfo, setUserInfo] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await axios.get(
          `${proxy}/api/landing-page/get-contact-data`,
          {
            params: {
              pageKey,
            },
          }
        );
        setUserInfo(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUserInfo();
  }, [pageKey]);

  const companyInfo = {
    name: "Video Home Shopping",
    address: "39 Allée Hélène Boucher, 1er étage",
    address2: "59118 Wambrechies",
    address3: "France",
    phone: "(+33) 03 59 55 41 58",
    website: "https://www.compactorstore.com",
  };

  const representative = {
    name: userInfo.name + " " + userInfo.surname,
    position: userInfo.role,
    email: userInfo.email,
    phone: userInfo.phone1,
    img: proxy + userInfo.image,
    isWhatsapp: userInfo.isWhatsapp,
  };

  useEffect(() => {
    console.log("Representative:", representative);
    console.log("User Info:", userInfo);
  }, [representative, userInfo]);


  const whatsappUrl = `https://wa.me/${userInfo.phone1}`;
  const whatsAppButton = `${proxy}/content/whatsAppButton.png`;

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, email, phone, message } = formData;
    const user_email = representative.email;
    const page_key = pageKey;

    try {
      const response = await fetch(`${proxy}/api/message/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName,
          email,
          phone,
          message,
          skus,
          page_key,
          user_email,
        }),
      });

      if (response.ok) {
        setEmailSent(true);
      } else {
        alert(
          "Failed to send email. Please try again or use the contact information provided."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error sending email.");
    }
  };

  const EmailSentConfirmation = () => {
    return (
      <Card.Body className="confirmation-container">
        <Card.Title>Thank you for your message!</Card.Title>
        <Card.Text className="my-5">
        <CustomSVG />
        </Card.Text>
        <Card.Text>
          Your email has been sent successfully. We will get back to you as soon
          as possible.
        </Card.Text>
      </Card.Body>
    );
  };

  return (
    <>
      <Container className="my-5">
        <Row className="mb-4">
          <Col>
            <h1>Contact Us</h1>
            <p>
              Feel free to reach out to us through the following contact
              information or by filling out the form below.
            </p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Company Information</Card.Title>
                <Card.Text>
                  <strong>{companyInfo.name}</strong>
                  <br />
                  {companyInfo.address}
                  <br />
                  {companyInfo.address2}
                  <br />
                  {companyInfo.address3}
                  <br />
                  <a href={`tel:${companyInfo.phone}`}>{companyInfo.phone}</a>
                  <br />
                  <a
                    href={companyInfo.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {companyInfo.website}
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Representative</Card.Title>
                <Row>
                  <Col md={8}>
                    <Card.Text>
                      <strong>{representative.name}</strong>
                      <br />
                      {representative.position}
                      <br />
                      <a href={`mailto:${representative.email}`}>
                        {representative.email}
                      </a>
                      <br />
                      <a href={`tel:${representative.phone}`}>
                        {representative.phone}
                      </a>
                    </Card.Text>
                  </Col>
                  <Col md={4} className="d-flex align-items-center">
                    <img
                      src={representative.img}
                      alt="Representative"
                      className="img-fluid rounded-circle "
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {representative.isWhatsapp !=0 && (<Card className="mb-4">
              <Card.Body>
                <Card.Title>Contact {userInfo.name} on WhatsApp</Card.Title>
                <Row>
                  <Col md={6}>
                    <Card.Text>Scan the QR code</Card.Text>
                    <QRCode value={whatsappUrl} size={200} />
                  </Col>
                  <Col md={6} className="">
                    <Card.Text>Or use the button below</Card.Text>
                    <div>
                      <a
                        href={`https://wa.me/${userInfo.phone1}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={whatsAppButton}
                          alt="WhatsApp Button"
                          className="img-fluid"
                          style={{ maxWidth: "200px" }}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>)}

          </Col>
          <Col md={6}>
            <Card className="mb-4">
              {emailSent ? (
                <EmailSentConfirmation />
              ) : (
                <ContactForm
                  handleSubmit={handleSubmit}
                  handleInputChange={handleInputChange}
                  formData={formData}
                  skus={skus}
                />
              )}

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactPage;
