import axios from 'axios';
import Cookies from 'js-cookie';
import proxy from './proxy.js';

// Utwórz niestandardową instancję Axios
const axiosInstance = axios.create({
  baseURL: proxy, // Zaktualizuj do swojego proxy
  headers: {
    'Content-Type': 'application/json',
  },
});

// Dodaj interceptor do dodawania tokena do każdego żądania
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
