import React, { createContext, useContext, useState } from 'react';
import {PopUpCornerSmall} from '../components/PopUpMessages';

// Stwórz kontekst
const PopupContext = createContext({
    showPopupSmall: () => {}
});

export const usePopup = () => useContext(PopupContext);

export const PopupProvider = ({ children }) => {
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('');
    const [trigger, setTrigger] = useState(0);

    const showPopupSmall = (newMessage, newMode) => {
        setMessage(newMessage);
        setMode(newMode);
        setTrigger(prev => prev + 1); // aktualizacja triggera
        console.log('Popup message:', newMessage);
        console.log("trigger:", trigger);
    };

    return (
        <PopupContext.Provider value={{ showPopupSmall }}>
            {children}
            <PopUpCornerSmall message={message} mode={mode} trigger={trigger} />
        </PopupContext.Provider>
    );
};
