import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import axiosInstance from '../../setup/axiosInstance';

const DescriptionsSKUform = () => {
    const [skuInput, setSkuInput] = useState('');
    const [languages, setLanguages] = useState([]);
    const [targetPages, setTargetPages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedTargetPages, setSelectedTargetPages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Pobranie dostępnych języków
        axiosInstance.get('/api/desc/languages')
            .then(response => setLanguages(response.data))
            .catch(error => console.error('Error fetching languages:', error));

        // Pobranie dostępnych target pages
        axiosInstance.get('/api/desc/target-pages')
            .then(response => setTargetPages(response.data))
            .catch(error => console.error('Error fetching target pages:', error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Przetwarzanie SKU na listę
        const skuList = skuInput.split(/[\n, ]+/).filter(sku => sku.trim() !== '');

        try {
            const response = await axiosInstance.post('/api/desc/get-queries-csv', {
                skuList,
                lang: selectedLanguages,
                targetPage: selectedTargetPages
            }, {
                responseType: 'blob' // Oczekiwanie na plik jako odpowiedź
            });

            // Tworzenie linku do pobrania pliku
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'output.csv');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error generating CSV:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLanguageChange = (lang) => {
        setSelectedLanguages(prev =>
            prev.includes(lang)
                ? prev.filter(l => l !== lang)
                : [...prev, lang]
        );
    };

    const handleTargetPageChange = (page) => {
        setSelectedTargetPages(prev =>
            prev.includes(page)
                ? prev.filter(p => p !== page)
                : [...prev, page]
        );
    };

    return (
        <div className="container d-flex my-5 align-items-center justify-content-center">
            <Card className="mb-4 shadow-sm"  style={{ maxWidth: '800px', width: '100%' }}>
                <Card.Header>Enter SKU List</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="skuInput">
                            <Form.Label>SKU List (comma, space, or new line separated)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={skuInput}
                                onChange={(e) => setSkuInput(e.target.value)}
                                placeholder="Enter SKUs..."
                            />
                        </Form.Group>

                        <Card className="my-4 shadow-sm">
                            <Card.Header>Select Languages</Card.Header>
                            <Card.Body>
                                {languages.map(lang => (
                                    <Form.Check
                                        key={lang.code}
                                        type="switch"
                                        id={`lang-switch-${lang.code}`}
                                        label={lang.name}
                                        checked={selectedLanguages.includes(lang.code)}
                                        onChange={() => handleLanguageChange(lang.code)}
                                    />
                                ))}
                            </Card.Body>
                        </Card>

                        <Card className="my-4 shadow-sm">
                            <Card.Header>Select Target Pages</Card.Header>
                            <Card.Body>
                                {targetPages.map(page => (
                                    <Form.Check
                                        key={page.id}
                                        type="switch"
                                        id={`target-page-switch-${page.code}`}
                                        label={page.name}
                                        checked={selectedTargetPages.includes(page.code)}
                                        onChange={() => handleTargetPageChange(page.code)}
                                    />
                                ))}
                            </Card.Body>
                        </Card>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Processing...
                                </>
                            ) : (
                                'Generate CSV'
                            )}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default DescriptionsSKUform;
