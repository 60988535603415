import React from "react";
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
      <div className="d-flex flex-column align-items-center text-center">
        <h1 className="mb-3">Welcome to Compactor Cloud</h1>
        <Link to="/changelog" className="text-muted">version 0.1.3</Link>
      </div>
    </div>
  );
}

export default Home;

