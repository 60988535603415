import React, { useState, useContext } from 'react';
import { usePopup } from '../context/PopupContext.js';
import './Login.css';
import proxy from '../setup/proxy.js';
import { AuthContext } from '../context/AuthContext';

function Login() {
    const { showPopupSmall } = usePopup();
    const { handleLoginSuccess } = useContext(AuthContext);
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [error, setError] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        fetch(`${proxy}/api/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                showPopupSmall("Invalid credentials", "error");
                throw new Error('Invalid credentials');
            }
            return response.json();
        })
        .then(data => {
            localStorage.setItem('user', formData.username);
            handleLoginSuccess(data.token);
            // Przekierowanie użytkownika lub wywołanie innego działania po zalogowaniu
        })
        .catch(error => {
            console.error('Error:', error);
            setError(error.message);
        });
    };

    return (
        <div className="container d-flex align-items-center vh-100">
            <div className="row w-100">
                <div className="col-12 col-md-6 offset-md-3 ">
                    <div className="card p-4">
                        <form onSubmit={handleFormSubmit}>
                            <div className="mb-3 d-flex justify-content-center">
                                <label className="form-label w-100">
                                    Username:
                                    <input
                                        type="text"
                                        name="username"
                                        className="form-control"
                                        value={formData.username}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="mb-3 d-flex justify-content-center">
                                <label className="form-label w-100">
                                    Password:
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Login</button>
                            {error && <p className="text-danger mt-3">{error}</p>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
