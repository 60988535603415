import axiosInstance from '../../setup/axiosInstance';
import proxy from '../../setup/proxy';

const fetchGeneralData = async (sku) => {
  const response = await axiosInstance.get(`${proxy}/api/desc/${sku}/descriptions`);

  return response.data;
}

const fetchDescriptionData = async (sku, lang, type) => {
  const response = await axiosInstance.get(`${proxy}/api/desc/${sku}/descriptions`, {
    params: {
      lang,
      type
    }
  });
 // return response.data;
    return "";
}

const saveDescriptionData = async (sku, data) => {

 const response =  await axiosInstance.put(`${proxy}/api/desc/${sku}/descriptions`, {
    data
  });
  return response;
}

const generateContent = async (sku, query) => {

  try{
   const response = await axiosInstance.post(`${proxy}/api/desc/${sku}/descriptions/generate`, {
      query
    }); // Nagłówki przekazywane jako osobny argument w axios
  return response;
  } catch (error) {
    console.error('Error generating content:', error);
  }
}

export { fetchGeneralData, fetchDescriptionData, saveDescriptionData, generateContent };
