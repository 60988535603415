function Maintenance() {
    return (
      <div className="d-flex align-items-center justify-content-center flex-column">
        <h1>Under Maintenance</h1>
        <p>We are currently performing maintenance. Please check back later.</p>
        <p>Contact @Kacper Fornalczyk on Teams to check the status</p>
      </div>
    );
  }
  
    export default Maintenance;