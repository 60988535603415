import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axiosInstance from '../setup/axiosInstance.js';
import proxy  from '../setup/proxy.js';

// Stworzenie kontekstu
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const storedToken = Cookies.get("token");
    if (storedToken) {
      verifyToken(storedToken).then((isValid) => {
        if (isValid) {
          setIsLoggedIn(true);
          fetchUserRoles(storedToken).then((userRoles) => {
            setRoles(userRoles);
            setIsLoading(false);
          });
        } else {
          Cookies.remove("token");
          setIsLoggedIn(false);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoggedIn(false);
      setIsLoading(false);
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      const response = await axiosInstance.post(`${proxy}/api/users/verify-token`, {}, {});
      return response.status === 200;
    } catch (error) {
      console.error("Error verifying token:", error);
      return false;
    }
  };

  const fetchUserRoles = async (token) => {
    try {
      const response = await axiosInstance.get(`${proxy}/api/users/get-user-privileges`, {});
      if (response.status === 200) {
        const data = response.data;
        return data.map(role => role.privilege_name);
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
      return [];
    }
  };

  const handleLoginSuccess = (token) => {
    Cookies.set("token", token);
    setIsLoggedIn(true);
    fetchUserRoles(token).then((userRoles) => {
      setRoles(userRoles);
    });
  };

  const logout = () => {
    Cookies.remove("token");
    localStorage.setItem("user", "");
    setIsLoggedIn(false);
    setRoles([]);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, isLoading, roles, handleLoginSuccess, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
