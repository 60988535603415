import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../setup/axiosInstance';


import proxy from '../setup/proxy';

// Stwórz kontekst
const TranslationContext = createContext();

export const useTranslationContext = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');
  
  const loadTranslationFile = useCallback(async (file, language) => {
    try {
      const response = await axiosInstance.get(`${proxy}/locales/${lang}/${file}.json`);
      setTranslations((prevTranslations) => ({
        ...prevTranslations,
        [file]: response.data,
      }));
    } catch (error) {
      if (error.response && error.response.status === 404 && language !== 'en') {
      //  console.warn(`Could not find ${file} for ${language}, falling back to English.`);
        await loadTranslationFile(file, 'en'); // Retry with 'en' if file not found
      } else {
       // console.error(`Error loading ${file} translations:`, error);
      }
    }
  }, []);

  const loadTranslations = useCallback(async (files) => {
    await Promise.all(files.map(loadTranslationFile));
  }, [lang]);



  useEffect(() => {
    localStorage.setItem('lang', lang);
    // Opcjonalnie można załadować podstawowe pliki tłumaczeń tutaj
  }, [lang]);

  return (
    <TranslationContext.Provider value={{ translations, lang, setLang, loadTranslations }}>
      {children}
    </TranslationContext.Provider>
  );
};
