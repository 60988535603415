import React, {useEffect} from 'react';
import { useTranslationContext } from '../../context/TranslationContext';
import './Tables.css';

const ProductTable = ({ data }) => {
  const { translations } = useTranslationContext();



  console.log("translations", translations);
  return (
    <table className="table table-responsive-sm table-light table-product">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Product Data</th>
        </tr>
      </thead>
      <tbody>
      {Object.entries(data).map(([key, value], index) => {
          let displayKey = key;
          let displayValue = value;

          switch (key) {
            case 'packaging_height_cm':
              displayKey = 'packaging_dimensions';
              displayValue = data.packaging_dimensions;
              break;
            case 'carton_s_length_cm':
              displayKey = 'carton_dimensions';
              displayValue = data.carton_dimensions;
              break;
            case 'product_gross_weight_with_packaging':
            case 'product_net_weight_without_packaging':
              displayValue = displayValue + ' kg';
              break;
            case 'quantity_in_pack':
              if (displayValue < 2 || displayValue === "" || displayValue === null) {
                displayValue = 'Single';
              }
              else{
                // change to intiger
                displayValue = parseInt(displayValue);
              }
              break;
            case 'pcb':
              displayValue = parseInt(displayValue) + ' pcs';
              break;
            case 'packaging_width':
            case 'packaging_depth':
            case 'carton_s_width_cm':
            case 'carton_s_height_cm':
            case 'packaging_dimensions':
            case 'carton_dimensions':
              return null; // Pomiń te klucze, zwracając null
            default:
              break;
      }

        


          return (
            <tr key={index}>
              <th scope="row">
                {(translations['landing_page'] && translations['landing_page'][displayKey]) || displayKey}
              </th>
              <td>{displayValue}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export {ProductTable};
