import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
// eslint-disable-next-line
import SKUList from "../components/SKUlist.js";
import ProductImagesToSort from "../components/ProductImagesDraggable.js";
// zapimportuj app.css
import "../styles/styles.css";
import { getAllSkusList } from "../components/utilities/GetMethods.js";

function ImageOrder() {
  // eslint-disable-next-line
  const [selectedSKU, setSelectedSKU] = useState("");
  const [skuList, setSkuList] = useState([]);

  useEffect(() => {
    const fetchSkus = async () => {
      try {
        const skus = await getAllSkusList();
        setSkuList(skus);
      } catch (error) {
        console.error("Error fetching SKU list:", error);
      }
    };

    fetchSkus();
  }, []);



  const handleSKUSelect = (sku) => {
    console.log("wybrany sku: ", sku);
    setSelectedSKU(sku);
    //markSelectedSKU(sku);

  };
  return (
    <Container fluid>
      <Row>
        <Col sm={2}>
          <SKUList onSKUSelect={handleSKUSelect} showFilterManualOrder={true} showVisualsQuantity={true} showFilterPhotosAvailable={true} showFilterVideosAvailable={true} skuList={skuList}/>
        </Col>
        <Col sm={8} className={"tiles-container"}>
          <ProductImagesToSort
            selectedSKU={selectedSKU}
            className={"image-tiles-draggable"}
          />
        </Col>
        <Col sm={2}>
          {/* <SKUList onSKUSelect={handleSKUSelect} /> */}
        </Col>
      </Row>
    </Container>
  );
}

export default ImageOrder;