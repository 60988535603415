import React, { useState,  useEffect} from "react";
import { Container, Row, Col } from "react-grid-system";
// eslint-disable-next-line
import SKUList from "../components/SKUlist.js";
import "../styles/styles.css";
import { ShowProductImagesWithResize } from '../components/ShowProductVisuals.js';
import { getAllSkusList } from "../components/utilities/GetMethods.js";

function ShowImagesResize() {
  const [selectedSKU, setSelectedSKU] = useState("");
  const [skuList, setSkuList] = useState([]);

  useEffect(() => {
    const fetchSkus = async () => {
      try {
        const skus = await getAllSkusList();
        setSkuList(skus);
      } catch (error) {
        console.error("Error fetching SKU list:", error);
      }
    };

    fetchSkus();
  }, []);


  const handleSKUSelect = (sku) => {
    console.log("wybrany sku: ", sku);
    setSelectedSKU(sku);
  };

  return (
    <Container fluid style={{minHeight:"93vh"}}>
      <Row>
        <Col sm={2}>
        <SKUList onSKUSelect={handleSKUSelect} showVisualsQuantity={true} showFilterPhotosAvailable={true} showFilterVideosAvailable={true} skuList={skuList}/>
        </Col>
        <Col sm={10} className="tiles-container">
          <ShowProductImagesWithResize selectedSKU={selectedSKU} />
        </Col>
      </Row>
    </Container>
  ); 
}

export default ShowImagesResize;