import React, { useState, useContext, useEffect } from "react";
import { generateContent, saveDescriptionData } from "./DescriptionsUtility";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Tab, Nav, Card } from 'react-bootstrap';
import { usePopup } from "../../context/PopupContext.js";
import { CategoryDivider } from "../visual/headers.js";
import { AuthContext } from "../../context/AuthContext";

const Descriptions = ({
  sku,
  data,
  saveDescriptionData,
  generalData,
  refreshGeneralData,
}) => {
  const [activeKey, setActiveKey] = useState('amazon-fr');
  const [localData, setLocalData] = useState({});
  const [localKeywords, setLocalKeywords] = useState('');
  const [localMetadescription, setLocalMetadescription] = useState('');
  const [localTitleShort, setLocalTitleShort] = useState('');
  const { showPopupSmall } = usePopup();
  const { roles } = useContext(AuthContext);
  const hasAccess = (role) => roles.includes(role);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedTpCode, setSelectedTpCode] = useState('amazon');
  const [selectedLangCode, setSelectedLangCode] = useState('fr');

  useEffect(() => {
    if (!activeKey && data.targetPages && data.languages) {
      setActiveKey(`${data.targetPages[0].code}-${data.languages[0].code}`);
    }
  }, [data]);

  useEffect(() => {
    if (activeKey) {
      const [type, lang] = activeKey.split('-');
      setSelectedTpCode(type);
      setSelectedLangCode(lang);


      if (data && data.descriptions && data.descriptions[type] && data.descriptions[type][lang]) {
        const currentData = data.descriptions[type][lang];
        const currentKeywords = currentData.keywords || '';
        const currentMetadescription = currentData.metadescription || '';
        const currentTitleShort = currentData.title_short || '';
        setLocalData(currentData);
        setLocalKeywords(currentKeywords || '');
        setLocalMetadescription(currentMetadescription || '');
        setLocalTitleShort(currentTitleShort || '');
      }

    }
  }, [activeKey, data]);

  const handleInputChange = (field, value) => {
    setLocalData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleKeywordsChange = (value) => {
    setLocalKeywords(value);
  };

  const handleMetaChange = (value) => {
    setLocalMetadescription(value);
  };

  const handleTitleShortChange = (value) => {
    setLocalTitleShort(value);
  };

  const handleBulletpointChange = (index, value) => {
    const newBulletpoints = [...localData.bulletpoints];
    newBulletpoints[index] = value;
    setLocalData((prevData) => ({
      ...prevData,
      bulletpoints: newBulletpoints,
    }));
  };

  const handleSaveChanges = async () => {
    const [type, lang] = activeKey.split('-');
    const updatedData = {
      sku: sku,
      type: type,
      bulletpoints: localData.bulletpoints,
      description: localData.description,
      title: localData.title,
      lang: lang,
      keywords: localKeywords,
      metadescription: localMetadescription,
    };

    const response = await saveDescriptionData(sku, updatedData);
    refreshGeneralData();
    showPopupSmall(`${JSON.stringify(response.data)}`, "success");
  };

  const handleGenerateContent = async () => {
    setIsGenerating(true);
    try {
      if (generalData.additionalInfo === undefined) {
        generalData.additionalInfo = "";
      }
  
      let additionalInfo = "";
      let highlights = "";
      let mostImportant = "";
      let langLong = "";
      let productType = "";
      let contentTarget = "";
  
      const [type, lang] = activeKey.split('-');

      switch (type) {
        case "amazon":
          contentTarget = "Amazon";
          break;
        case "website":
          contentTarget = "Shopify Store";
          break;
        default:
          contentTarget = "Amazon";
      }
  
      switch (lang) {
        case "en":
          langLong = "english";
          break;
        case "de":
          langLong = "german";
          break;
        case "fr":
          langLong = "french";
          break;
        case "es":
          langLong = "spanish";
          break;
        case "it":
          langLong = "italian";
          break;
        default:
          langLong = "english";
      }
  
      switch (generalData.commercial_category) {
        case "VAC3D SEMI RIGID":
          highlights = `Saves up to 75% of space,integrated vacuum bag, patented, reusable, durable, space-saving, airtight, waterproof, dustproof, mothproof, moldproof, odor-proof, easy to use, easy to store, easy to transport, easy to carry`;
          mostImportant = "Save up to 75% of space";
          productType = "semi-rigid storage box with integrated vacuum bag";
          break;
        default:
          highlights = `best quality, save space, simple to use, reliable, pretty, fits in home, helps organize your home`;
          mostImportant = "best quality";
          productType = "storage item";
      }
  
      const query = `
      Below you will have information about a product that you need to create text content for (e-commerce optimized for ${contentTarget}).
      Generate content in ${langLong}.
      Fill and send back a JSON file in the JSON format, without any other text or characters.
      The JSON file should contain the following fields:
      - 'title_amazon' (max 200 characters)
      - 'bulletpoints_amazon' (5 bulletpoints with emoticons, max 250 characters each)
      - 'description_amazon' (max 2000 characters)
      - 'keywords_amazon' (max 250 characters)
      - 'metadescription_amazon' (max 200 characters)
  
      Product data
      Producer: Compactor - a leading brand for home organization and vacuum storage solutions [https://www.compactorstore.com/];
      SKU: ${sku}
      Business desc: '${generalData.business_description}'
      Product type: ${productType}
      highlights: ${highlights}
      Most important: ${mostImportant}
      Additional information: ${generalData.additionalInfo}
      `;
  
      const response = await generateContent(sku, query);

      setLocalData((prevData) => ({
        ...prevData,
        title: response.data.data.title_amazon,
        description: response.data.data.description_amazon,
        bulletpoints: response.data.data.bulletpoints_amazon,
      }));
      setLocalKeywords(response.data.data.keywords_amazon);
      setLocalMetadescription(response.data.data.metadescription_amazon);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="container mt-4">
      {
        (hasAccess("superuser") || hasAccess("descriptions_editor")) ? (<CategoryDivider category="Descriptions generator" />) : ""
      }

<Tab.Container activeKey={activeKey}>
  <Card className="text-center">
    <Card.Header>
      <Nav variant="tabs" className="card-header-tabs justify-content-center">
        {data.targetPages && data.targetPages.map(tp => (
          <Nav.Item key={tp.code}>
            <Nav.Link
              eventKey={tp.code}
              className={`tab-styles ${tp.code} ${selectedTpCode === tp.code ? 'active' : ''}`}
              disabled={isGenerating}
              onClick={() => {
                setSelectedTpCode(tp.code);
                setActiveKey(`${tp.code}-${selectedLangCode}`); // Domyślnie ustaw język na francuski
              }}
            >
              {tp.name}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </Card.Header>
    <Card.Body>
      {selectedTpCode && (
        <Nav variant="pills" className="justify-content-center">
          {data.languages.map(lang => (
            <Nav.Item key={`${selectedTpCode}-${lang.code}`}>
              <Nav.Link
                eventKey={`${selectedTpCode}-${lang.code}`}
                className={`tab-styles ${selectedTpCode} ${selectedLangCode === lang.code ? 'active' : ''}`}
                disabled={isGenerating}
                onClick={() => setActiveKey(`${selectedTpCode}-${lang.code}`)}
              >
                {lang.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      )}
      <Tab.Content>
        {(hasAccess("superuser") || (hasAccess("description_editor") && selectedLangCode)) && (
          
          <div className="form-group row pb-3 my-3">
            <hr className="hr hr-blurry" />
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-primary mx-2"
                onClick={handleGenerateContent}
                disabled={isGenerating}
              >
                {isGenerating ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Generating...
                  </>
                ) : (
                  "Generate content"
                )}
              </button>
              <button
                className="btn btn-success mx-2"
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        )}
        {data.targetPages && data.targetPages.map(tp => (
          data.languages.map(lang => (
            <Tab.Pane eventKey={`${tp.code}-${lang.code}`} key={`${tp.code}-${lang.code}`}>
              {renderContentForm(tp.code, lang.code)}
            </Tab.Pane>
          ))
        ))}
      </Tab.Content>
    </Card.Body>
  </Card>
</Tab.Container>


    </div>
  );

  function renderContentForm(contentType, lang) {
    return (
      <div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Title:</label>
          <div className="col-sm-10">
            <textarea
              type="text"
              className="form-control"
              value={localData.title || ''}
              onChange={(e) => handleInputChange('title', e.target.value)}
            />
            <small className="text-muted">
              {localData.title ? localData.title.length : 0} characters
            </small>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Keywords:</label>
          <div className="col-sm-10">
            <textarea
              type="text"
              className="form-control"
              value={localKeywords || ''}
              onChange={(e) => handleKeywordsChange(e.target.value)}
            />
            <small className="text-muted">
              {localKeywords ? localKeywords.length : 0} characters
            </small>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Bulletpoints:</label>
          <div className="col-sm-10">
            {(localData.bulletpoints || []).map((bp, index) => (
              <div key={index}>
                <textarea
                  type="text"
                  className="form-control mb-2 h-90px"
                  value={bp}
                  onChange={(e) => handleBulletpointChange(index, e.target.value)}
                />
                <small className="text-muted">
                  {bp ? bp.length : 0} characters
                </small>
              </div>
            ))}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Description:</label>
          <div className="col-sm-10">
            <textarea
              className="form-control h-300px"
              value={localData.description || ''}
              onChange={(e) => handleInputChange('description', e.target.value)}
            />
            <small className="text-muted align-tems-end">
              {localData.description ? localData.description.length : 0} characters
            </small>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Metadescription:</label>
          <div className="col-sm-10">
            <textarea
              type="text"
              className="form-control"
              value={localMetadescription || ''}
              onChange={(e) => handleMetaChange(e.target.value)}
            />
            <small className="text-muted">
              {localMetadescription ? localMetadescription.length : 0} characters
            </small>
          </div>
        </div>
      </div>
    );
  }
  
};

export default Descriptions;
