import { DataForm } from '../components/ExcelGenerationForm.js';

function GetProductsData() {

    return (
       <div className="h-100" style={{minHeight:"88vh"}}>
            <DataForm  />  
       </div>
          );
        }

export  {GetProductsData};