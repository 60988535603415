import React from 'react';
import { usePopup } from '../context/PopupContext.js';

import { useTranslation } from 'react-i18next';
import { DownloadImages } from './utilities/GetMethods.js';

function CloseButton({ className }) {
    return (
        <svg
            className={className} // Przypisz className do svg
            width="35px" // Zmieniłem szerokość i wysokość na mniejsze, bardziej typowe dla przycisków zamykania
            height="35px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="1">
                <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function ButtonDelete (){
    return(
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48"className="tiles-img-btn-del">
        <path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
        </svg>
        )
}

const DownloadButton = ({ text, skus, size = 0 }) => {
    const { t } = useTranslation();
    const { showPopupSmall } = usePopup();
  
    const onClick = async () => {
        DownloadImages(skus, size, showPopupSmall);
    };
  
    return (
      <button className="btn btn-primary" onClick={onClick}>
        {t(text)}
      </button>
    );
  };

export {ButtonDelete, CloseButton, DownloadButton};