import React, { useState } from 'react';
import ProductsBrowserTable from '../components/productsBrowser/ProductsBrowserTable'; 
import SearchSKUForm from '../components/productsBrowser/ProductsBrowserSearchForm';  
import proxy from '../setup/proxy.js';
import axiosInstance from '../setup/axiosInstance';
import ProductsColumnSelector from '../components/productsBrowser/ProductsColumnSelector';



const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [columns, setColumns] = useState([]);  // Pusta lista na początku
    const [availableColumns, setAvailableColumns] = useState([]);  // Dostępne kolumny

    const fetchProductsBySKU = async (skuList) => {
        try {
            const response = await axiosInstance.post(`${proxy}/api/products/get-data-json`, { product: skuList });

            if (response.status !== 200) {
                throw new Error('Błąd podczas pobierania danych');
            }

            const data = response.data;

            if (data.length > 0) {
                const columnsFromData = Object.keys(data[0]);
                setAvailableColumns(columnsFromData);
                setColumns(columnsFromData.slice(0, 2));  // Domyślnie wybierz pierwsze dwie kolumny
            }

            setProducts(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleSearch = (skuList) => {
        fetchProductsBySKU(skuList);
    };

    return (
        <div>
            <h1>Wyszukiwarka produktów</h1>
            <SearchSKUForm onSearch={handleSearch} />
            <ProductsColumnSelector 
                availableColumns={availableColumns} 
                selectedColumns={columns} 
                onColumnsChange={setColumns} 
            />
            <ProductsBrowserTable 
                products={products} 
                selectedColumns={columns} 
            />
        </div>
    );
};

export default ProductsPage;
