import React from 'react';
import './ProductCard.css';
import proxy from '../setup/proxy.js'

const ProductCard = ({ imageSrc, productName, productEan, productCode, productSize, sizeTag, pageKey, onClick, handleCheckboxChange, isChecked }) => {

  imageSrc = `${proxy}/img/main/250/${productCode}`;
  return (
    <div className="product-card" onClick={onClick}>
      <input
        type="checkbox"
        className="product-checkbox form-check-input" 
        checked={isChecked}
        onChange={(e) => {
          e.stopPropagation(); // Zapobiega propagacji zdarzenia
          handleCheckboxChange();
        }}
        onClick={(e) => e.stopPropagation()} // Zapobiega propagacji zdarzenia
      />
      <img src={imageSrc} alt={productName} className="product-image" />
      <div className="product-info">
        <h3 className="product-code">{productCode}</h3>
        {productEan && (<h4 className="product-ean">EAN: {productEan}</h4>)}
        <h4 className="product-name">{productName}</h4>
        {productSize !== "---" && (
          <p className="product-size">
            {sizeTag && (<span>Size: {sizeTag} (</span>)}
            {productSize}
            {sizeTag && (<span>)</span>)}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
