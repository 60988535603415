import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import axiosInstance from '../setup/axiosInstance';
import proxy from '../setup/proxy';
import { Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';

function AdminControl() {
    const [loading, setLoading] = useState(false);
    const [dbTables, setDbTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState('');
    const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
    const [order, setOrder] = useState('int-ext');

    // maintenence handeling
    useEffect(() => {
        const fetchState = async () => {
          try {
            const response = await axiosInstance.get(`${proxy}/api/app-state`);
            setIsUnderMaintenance(response.data.isUnderMaintenance || false); // Zabezpieczenie przed undefined
          } catch (error) {
            console.error(error);
          }
        };
        fetchState();
    }, []);
      
    const handleToggle = async () => {
        const newState = !isUnderMaintenance; // Użyjemy nowego stanu bezpośrednio
    
        try {
            const response = await axiosInstance.post(`${proxy}/api/app-state`, { isUnderMaintenance: newState });
            setIsUnderMaintenance(response.data.isUnderMaintenance); // Zaktualizuj stan na podstawie odpowiedzi z backendu
        } catch (error) {
            console.error(error);
        }
    };

    const handleToggleOrder = () => {
        setOrder(order === 'int-ext' ? 'ext-int' : 'int-ext');
    };

    useEffect(() => {
        const fetchDbTables = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`${proxy}/api/db/tables`);
                
                // Sprawdzenie, czy dane są już tablicą; jeśli nie, przekształcenie na tablicę
                const tables = Array.isArray(response.data.tables) ? response.data.tables : [response.data.tables];
                
                setDbTables(tables);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchDbTables();
    }, []);
    

    const handleCleanImg = async () => {
        const confirmed = window.confirm("Are you sure you want to clean the images database?");
        if (!confirmed) {
            return;  // Anuluj operację, jeśli użytkownik kliknie "Cancel"
        }

        setLoading(true);
        try {
            const response = await axiosInstance.get(`${proxy}/api/img/clean`);
            console.log(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const scanImagesToJson = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${proxy}/api/img/scan-images`);
            console.log(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const scanVideosToJson = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${proxy}/api/img/scan-videos`);
            console.log(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddFIlesToDatabase = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${proxy}/api/img/process-json-to-sql`);
            console.log(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSortImages = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${proxy}/api/img/sort-images`);
            console.log(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloneTable = async () => {
        if (!selectedTable) {
            console.error('No table selected');
            return;
        }
    
        setLoading(true);
        try {
            const response = await axiosInstance.post(`${proxy}/api/db/clone-table`, { tableName: selectedTable, order: order});
            console.log(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
    <Card className="shadow-sm mb-5">
        <Card.Header as="h3" className="text-center">
            Admin Control
        </Card.Header>
        <Card.Body>
            <div className="d-flex flex-wrap justify-content-center gap-3">
                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={handleCleanImg} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Clean Images DB'}
                </Button>

                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={scanImagesToJson} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Scan IMAGES and put to JSON'}
                </Button>

                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={scanVideosToJson} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Scan VIDEOS and put to JSON'}
                </Button>

                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={handleAddFIlesToDatabase} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Add Files to DB'}
                </Button>

                <Button 
                    variant="primary" 
                    size="lg" 
                    onClick={handleSortImages} 
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Sort Images in DB'}
                </Button>
            </div>
        </Card.Body>
    </Card>

    <Card className="shadow-sm">
        <Card.Header as="h4" className="text-center">
            Database Tables
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center">
            <Form.Group controlId="formTableSelect" className="w-50">
                <Form.Label>Select a table</Form.Label>
                <Form.Control 
                    as="select"
                    value={selectedTable} 
                    onChange={(e) => setSelectedTable(e.target.value)}
                    className="mb-3"
                >
                    <option value="" disabled>Select a table</option>
                    {dbTables.map((table, index) => (
                        <option key={index} value={table}>{table}</option>
                    ))}
                </Form.Control>
                <Form.Check
                    type="switch"
                    id="under-maintenance-switch"
                    label="From external to internal?"
                    onChange={handleToggleOrder}
                />
            </Form.Group>
            
            <Button 
                variant="primary" 
                size="lg" 
                onClick={handleCloneTable} 
                disabled={loading || !selectedTable}
            >
                {loading ? 'Processing...' : 'Clone Table'}
            </Button>
        </Card.Body>
    </Card>

    <Card className="shadow-sm">
        <Card.Header as="h4" className="text-center">
            Maintenence switches
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center">
        <Form.Check
            type="switch"
            id="under-maintenance-switch"
            label="Under Maintenance"
            checked={isUnderMaintenance}
            onChange={handleToggle}
        />
      
        </Card.Body>
    </Card>
</div>

    );
}

export default AdminControl;
