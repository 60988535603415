import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';

const PopUpMessages = ({ show, message, onConfirm, onCancel }) => {
  return (
    <Modal show={show} onHide={() => onCancel(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => onConfirm(true)}>Confirm</Button>
        <Button variant="secondary" onClick={() => onCancel(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

const PopUpCornerSmall = ({ message, mode, trigger }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    let className;

    switch (mode) {
        case 'success':
            className = 'popup-corner-small success';
            break;
        case 'error':
            className = 'popup-corner-small error';
            break;
        default:
            className = 'popup-corner-small';
    }

    useEffect(() => {
        if (message) {
            setIsVisible(false); // Ukryj, a następnie pokaż popup, aby zrestartować animację
            setTimeout(() => { // Opóźnienie, aby dać czas na zresetowanie stanu
                setIsVisible(true);
                setAnimationClass('fade-in');
                // Ustawienie timeoutu na zmianę klasy animacji na 'fade-out'
                setTimeout(() => {
                    setAnimationClass('fade-out');
                  
                    // Ustawieni kolejnego timeoutu na usunięcie popupu po zakończeniu animacji fade-out
                    setTimeout(() => {
                        setIsVisible(false);
                       
                    }, 500); // Czas trwania animacji fade-out
                }, 3500); // Czas wyświetlania popupu przed rozpoczęciem znikania
            }, 10);
        }
    }, [trigger, message]);

    return isVisible ? (
        <div className={`${className} ${animationClass}`}>
            <p className={"popup-corner-small--text"}>{message}</p>
        </div>
    ) : null;
};


export {PopUpMessages, PopUpCornerSmall};
